<!-- 基本信息 -->
<template>
  <RightSide>
    <template v-slot:tit>基本信息</template>
    <template v-slot:tit_right>
      <!-- <div class="flex_1 flex--c">
        进度 <el-progress class="ml-15" :percentage="10" :stroke-width="16" define-back-color="#F5F6FA"></el-progress>
      </div> -->
    </template>
    <template v-slot:write_msg>
      <el-form ref="formform" label-width="80px">
        <div class="flex">
          <span>企业名称<span class="red"> *</span></span>
          <div class="text">
            <el-input v-model="companyInfo.company_name" @blur="checkCompany" placeholder="请输入企业名称"></el-input>
            <div class="red">注：认证企业全称，需要与营业执照名称相符，否则将影响认证审核。</div>
          </div>
        </div>
        <div class="flex">
          <span>企业简称<span class="red"> *</span></span>
          <div class="text">
            <el-input style="width: 150px;" v-model="companyInfo.brief_name" @input="checkShort" placeholder="请输入企业简称" :readonly="readonly"></el-input>
            <div class="red">注：企业名称简称为大写英文字母且不超过4位；如：河北证优科技有限公司，简称为ZYKJ。</div>
          </div>
        </div>
        <div class="flex">
          <span>所属区域<span class="red"> *</span></span>
          <div class="text">
            <Linkage :first_id="province_id" :second_id="city_id" :third_id="country_id" length="3" :key="areaKey" @changeEvent="changeArea">
              <div class="fill"></div>
            </Linkage>
          </div>
        </div>
        <div class="flex">
          <span>所属行业<span class="red"> *</span></span>
          <div class="text">
            <Linkage :first_id="industrys_pid" :second_id="industrys_id" type="industrys" length="2" @changeEvent="changeIndustrys">
              <div class="fill"></div>
            </Linkage>
          </div>
        </div>

        <!-- 邮编 -->
        <div class="flex">
          <span>企业邮编</span>
          <div class="text">
            <el-input style="width: 100px;" v-model="companyInfo.postcode" placeholder="企业邮编" :readonly="readonly"></el-input>
          </div>
          <span style="margin-left: 10px;">注册资本<span class="red"> *</span></span>
          <div class="text">
            <el-input style="width: 100px;" v-model="companyInfo.registered_capital" placeholder="注册资本" :readonly="readonly"></el-input>
          </div>
          <span style="margin-left: 10px;">成立年限<span class="red"> *</span></span>
          <div class="text">
<!--            <el-input style="width: 100px;" v-model="companyInfo.establishment_period" placeholder="成立年限" :readonly="readonly"></el-input>-->
            <el-date-picker
                v-model="companyInfo.establishment_period"
                type="year"
                placeholder="成立年限"
                format="YYYY"
                style="width:100px;"
            />
          </div>
        </div>
        <!-- 产品名称 -->
        <div class="flex">
          <span>产品名称<span class="red"> *</span></span>
          <div class="text">
            <el-button @click="addProduct" class="addbtn" style="width: 100px; font-size: 14px; margin-bottom: 10px;">产品管理</el-button>
            <el-input-tag v-show="product_flag" v-model="companyInfo.product_list" tag-type="" tag-effect="plain" clearable readonly style="width: 600px;" />
<!--            <el-input v-model="companyInfo.product_name" placeholder="请输入产品名称" :readonly="readonly"></el-input>
            <div class="red">注：填写产品名称，多产品中间用“、”分割。</div>-->
<!--            <el-table :data="companyInfo.productList" border show-header>
              <el-table-column label="名 称" width="200" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.name" placeholder="请输入名称"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="age" fixed="right" label="操 作" width="100" align="center">
                <template #default="scope">
                  <i class="red icon-jianqu hand" @click="deleteTr(scope.$index)" style="margin-right: 10px;"></i>
                  <i class="blue icon-tianjia hand" @click="addTr(scope.$index)"></i>
                </template>
              </el-table-column>
            </el-table>-->
          </div>
          <ManageProduct ref="visibleDialog" @setProduct="setProduct" @delProduct="delProduct"></ManageProduct>
        </div>
        <!-- 企业简介 -->
        <div class="flex">
          <span>企业简介<span class="red"> *</span></span>
          <div class="text" style="width: 600px">
            <div style="align-items: center;">
              <el-select placeholder="请选择企业简介" style="width: 200px;" v-model="companyInfo.brief_id" value-key="id" @change="fun_show();">
                <el-option :value="0" label="请选择简介"></el-option>
                <el-option :value="99" label="自定义"></el-option>
                <el-option v-for="it in briefList" :key="it.id" :id="it.id" :label="it.title" :value="parseInt(it.id)"></el-option>
              </el-select>
              <el-button type="primary" plain v-if="dialog_flag" @click="openBriefDialog(companyInfo.brief_id)">预览</el-button>
              <el-input type="textarea" v-if="show_flag" :autosize="{ minRows: 2, maxRows: 5 }" v-model="companyInfo.brief" placeholder="请输入企业简介"></el-input>
            </div>
            <div class="red">注：您可以自行编写输入企业简介，也可以进行快捷选择简介模版。</div>
          </div>
        </div>
        <!--企业简介预览对话框-->
        <el-dialog v-model="briefDialogVisible" title="企业简介预览">
          <template #default>
            <div v-text="company_brief" size="large" style="text-indent:30px; line-height: 25px;"></div>
          </template>
          <template #footer>
            <div class="dialog-footer">
              <el-button type="primary" @click="briefDialogVisible = false">关闭</el-button>
            </div>
          </template>
        </el-dialog>
        <!-- 企业方针 -->
        <div class="flex mt-10">
          <span>企业方针</span>
          <div class="text" style="width: 600px">
            <div style="align-items: center;">
              <el-select v-model="quality_policy" placeholder="请选择企业方针" style="width: 200px;" class="mb-10" @change="changePolicy">
                <el-option v-for="(it,k) in policyList" :key="k" :label="it.title" :value="it.content"></el-option>
              </el-select>
              <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" v-model="companyInfo.quality_policy" placeholder="请输入企业方针" readonly="readonly"></el-input>
            </div>
            <div class="red">注：根据企业的性质选择一个方针即可。</div>
          </div>
        </div>
        <!-- 认证人数 -->
        <div class="flex mt-10">
          <span>认证人数<span class="red"> *</span></span>
          <div class="text">
            <el-input style="width: 100px;" v-model="companyInfo.cert_people_num" placeholder="请输入认证人数" :readonly="readonly"></el-input>
            <!-- <div class="red">注：企业名称简称为大写英文字母且不超过4位；如：河北证优科技有限公司，简称为ZYKJ。</div> -->
          </div>
        </div>
        <!-- 认证范围 -->
        <div class="flex">
          <span>认证范围<span class="red"> *</span></span>
          <div class="text" style="width: 600px">
            <div class="flex" style="align-items: center;">
              <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" v-model="companyInfo.scope" placeholder="请输入认证范围" :readonly="readonly"></el-input>
            </div>
            <div class="red">注：填写此内容前请于机构或者咨询师确认范围后在进行填写。</div>
          </div>
        </div>
        <!-- 顾客满意度 -->
        <div class="flex">
          <span>顾客满意度<span class="red"> *</span></span>
          <div class="text">
            <el-select v-model="companyInfo.satisfaction" placeholder="请选择" style="width: 80px">
              <el-option
                  v-for="item in satisfaction_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <!-- 产品合格率 -->
        <div class="flex">
          <span>产品合格率<span class="red"> *</span></span>
          <div class="text">
            <el-select v-model="companyInfo.product_percent" placeholder="请选择" style="width: 80px">
              <el-option
                  v-for="item in product_percent_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <!-- 质量目标 -->
        <!-- <div class="flex">
          <span>质量目标</span>
          <div class="text" style="width: 600px">
            <div class="flex" style="align-items: center;">
              <el-select  placeholder="请选择质量目标" style="width: 200px;" @change="changeQuality">
                <el-option v-for="(it,k) in qualityList" :key="k" :label="it.title" :value="it.content"></el-option>
              </el-select>
              &nbsp;&nbsp;&nbsp;
              <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" v-model="companyInfo.quality_target" placeholder="请输入质量目标" :readonly="readonly"></el-input>
            </div>
            <div class="red">注：根据企业的性质选择一个质量目标，也可以自行添加。</div>
          </div>
        </div> -->
        <!-- 营业执照 -->
        <el-form-item label="营业执照">
          <span class="color_9" style="width: 100%;">电子版营业执照，不能遮挡、涂改，不可加水印</span>
          <div class="flex_wrap pr">
          <el-upload class="avatar-uploader"
                     list-type="picture-card"
                     ref="uploadRef"
                     :show-file-list="true"
                     :multiple="false"
                     :limit="1"
                     :on-exceed="handleExceed"
                     accept="image/png,image/gif,image/jpg,image/jpeg"
                     :before-upload = "handleBeforeUpload"
                     :on-change = "handleChange"
                     :on-preview="handlePictureCardPreview"
                     :on-remove="handleRemove"
                     :http-request="upload_submit"
                     :headers="headers"
                     :auto-upload="true">
            <div class="text_center">
              <i class="blue icon-xiangji avatar-uploader-icon"></i>
              <div class="blue f14">选择营业执照</div>
            </div>
          </el-upload>
<!--            <el-button type="primary" v-if="upload_btn_flag" style="width: auto; font-size: 14px; position: absolute; left: 170px; top: 115px;" @click="upload_submit()">图片上传</el-button>-->
            <el-button type="primary" v-if="upload_loading_btn_flag" style="width: auto; font-size: 14px; position: absolute; left: 170px; top: 115px;" loading>正在上传</el-button>
          </div>
          <img :src="site+companyInfo.license" v-if="companyInfo.license" @click="previewImg(site+companyInfo.license)" style="width: 200px; cursor: pointer;">
        </el-form-item>
      </el-form>
    </template>
    <template v-slot:foot_msg>
        <span class="red" style="line-height: 30px; margin-top: 15px; float: left;"> 标“*”为必填项</span>
        <button @click="modifyCompany" style="float:right;">保存</button>
    </template>
  </RightSide>
</template>
<script>
import {ref, reactive, toRefs, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'
import Linkage from '@/components/common/Linkage.vue'
import {layer} from '@layui/layer-vue'
import ManageProduct from "@/components/common/ManageProduct.vue";

export default {
  name: 'BaseInfo',
  components: {
    RightSide,
    Linkage,
    ManageProduct
  },
  props: {},
  setup(props, context) {
    const {proxy} = getCurrentInstance();
    const Api = proxy.$commonApi;
    //公共数据
    var project_id = Api.sessionGet('project_id');
    let headers = Api.getHeader();
    let _this = reactive({
      action: Api.UPLOAD_URL,
      site: Api.WEB_SITE,
      companyInfo: [],
      project_id: project_id,
      company_id: 0,
      headers: {
        USERKEY: headers.userKey,
        USERTOKEN: headers.token
      },
      policyList: [],
      quality_policy: '',
      briefList: [],
      qualityList: [],
    });
    // 产品对话框管理
    let visibleDialog = ref(false);
    let product_flag = ref(false); // 产品是否显示
    let readonly = ref(false)//只读
    let show_flag = ref(false); // 企业简介输入框是否显示
    let dialog_flag = ref(false); // 选择简介后显示
    let briefDialogVisible = ref(false);  // 简介预览对话框显示标识
    let company_brief = ref();  // 动态加载简介内容
    let upload_btn_flag = ref(false); // 上传按钮是否显示
    let upload_loading_btn_flag = ref(false); // 正在上传按钮状态
    const fileList = ref([]);
    let uploadRef = ref();
    const product_percent_options = [
      {
        value: '90',
        label: '90',
      },
      {
        value: '91',
        label: '91',
      },
      {
        value: '92',
        label: '92',
      },
      {
        value: '91',
        label: '91',
      },
      {
        value: '93',
        label: '93',
      },
      {
        value: '94',
        label: '94',
      },
      {
        value: '95',
        label: '95',
      },
      {
        value: '96',
        label: '96',
      },
      {
        value: '97',
        label: '97',
      },
      {
        value: '98',
        label: '98',
      },
      {
        value: '99',
        label: '99',
      },
      {
        value: '100',
        label: '100',
      }
    ];
    const satisfaction_options = [
      {
        value: '90',
        label: '90',
      },
      {
        value: '91',
        label: '91',
      },
      {
        value: '92',
        label: '92',
      },
      {
        value: '91',
        label: '91',
      },
      {
        value: '93',
        label: '93',
      },
      {
        value: '94',
        label: '94',
      },
      {
        value: '95',
        label: '95',
      },
      {
        value: '96',
        label: '96',
      },
      {
        value: '97',
        label: '97',
      },
      {
        value: '98',
        label: '98',
      },
    ];

    onMounted(() => {
      getCompanyInfo();
    })
    //地区
    let province_id = ref(0);
    let city_id = ref(0);
    let country_id = ref(0);
    //行业
    let industrys_pid = ref(0);
    let industrys_id = ref(0);

    //获取企业信息
    const getCompanyInfo = () => {
      var load = Api.loading()
      proxy.$post("/company/detail", {
        company_id: _this.company_id,
        project_id: _this.project_id,
      }).then((response) => {
        //企业信息
        _this.companyInfo = response.info;
        if (_this.companyInfo.product_list.length > 0){
          product_flag.value = true;
        }
        //政策方针
        _this.policyList = response.policyList;
        _this.quality_policy = _this.companyInfo.quality_policy;
        //企业简介
        _this.briefList = response.briefList;
        //质量目标
        //_this.qualityList = response.qualityList
        if (_this.companyInfo.id > 0) {
          updateCompany(_this.companyInfo);
        }
        Api.close(load);
      })
    }
    //更新企业信息
    const updateCompany = (companyInfo) => {
      _this.companyInfo = companyInfo
      province_id.value = companyInfo.province
      city_id.value = companyInfo.city
      country_id.value = companyInfo.country
      industrys_pid.value = companyInfo.industrys_pid
      industrys_id.value = companyInfo.industrys_id
      _this.company_id = companyInfo.id
      fun_show();
    }
    //地区选择回调
    const changeArea = (data) => {// eslint-disable-line no-unused-vars
      // console.log(data);
      province_id.value = data[0] || 0
      city_id.value = data[1] || 0
      country_id.value = data[2] || 0
    }
    //认证项目回调
    const changeIndustrys = (data) => {// eslint-disable-line no-unused-vars
      industrys_pid.value = data[0] || 0
      industrys_id.value = data[1] || 0
    }
    //编辑基本信息
    const modifyCompany = () => {
      _this.companyInfo.province = province_id.value
      _this.companyInfo.city = city_id.value
      _this.companyInfo.country = country_id.value
      _this.companyInfo.industrys_pid = industrys_pid.value
      _this.companyInfo.industrys_id = industrys_id.value
      let companyInfo = _this.companyInfo
      companyInfo.project_id = _this.project_id
      companyInfo.company_id = _this.company_id
      // let companyInfo = _this.companyInfo

      let company_name = companyInfo.company_name;
      if (!company_name) {
        Api.msg('请填写企业名称！', 'error', 25);
        return false;
      }
      if (!Api.checkPreg(company_name, 'company')) {
        Api.msg('请填写正确的企业名称！', 'error', 25);
        return false;
      }
      let brief_name = companyInfo.brief_name;
      if (!brief_name) {
        Api.msg('请填写企业简称！', 'error', 25);
        return false;
      }
      var preg = /[A-Z]{1,4}/
      if (brief_name.length > 4 || !preg.test(brief_name)) {
        Api.msg('企业简称请填写4位以内的大写字母！', 'error', 25);
        return false;
      }
      if (!city_id.value) {
        Api.msg('请选择城市信息！', 'error', 25);
        return false;
      }
      if (!industrys_id.value) {
        Api.msg('请选择行业信息！', 'error', 25);
        return false;
      }
      /*var postcode = companyInfo.postcode
      if (!postcode) {
        Api.msg('请填写企业邮编！', 'error', 25);
        return false;
      }*/
      // 如果是生产型企业
      var product_name = companyInfo.product_name
      if (!product_name) {
        Api.msg('请填写产品名称！', 'error', 25);
        return false;
      }

      var quality_policy = companyInfo.quality_policy
      if (!quality_policy) {
        Api.msg('请选择企业方针！', 'error', 25);
        return false;
      }

      /*var brief = companyInfo.brief
      if (!brief) {
        Api.msg('请填写企业简介！', 'error', 25);
        return false;
      }*/
      //认证人数
      var cert_people_num = companyInfo.cert_people_num;
      if (!cert_people_num) {
        Api.msg('请填写认证人数！', 'error', 25);
        return false;
      }else if (!Api.isNUmber(cert_people_num)) {
        Api.msg('认证人数只写数字！', 'error', 25);
        return false;
      }
      var scope = companyInfo.scope
      if (!scope) {
        Api.msg('请填写认证范围！', 'error', 25);
        return false;
      }
      var satisfaction = companyInfo.satisfaction
      if (!satisfaction) {
        Api.msg('请填写客户满意度！', 'error', 25);
        return false;
      }
      var product_percent = companyInfo.product_percent
      if (!product_percent) {
        Api.msg('请填写产品合格率！', 'error', 25);
        return false;
      }
      // var quality_target = companyInfo.quality_target
      // if(!quality_target){
      //   Api.msg('请填写质量目标！');return false;
      // }
      /*let license = _this.companyInfo.license
      if (!license) {
        Api.msg('请上传企业资质！', 'error', 25);
        return false;
      }*/
      proxy.$post('company/addCompany', companyInfo).then((res) => {
        if (res.code == 0 || res.code == 200) {
          resetPercent();
          Api.msg(res.msg, 'success', 25);
        }else {
          Api.msg(res.msg, 'error', 25);
        }
      });
    }
    //更新进度
    const resetPercent = () => {
      let percent = 100
      let data = {}
      data.template_id = 0;
      data.percent = percent;
      context.emit("updatePercent", data)
    }
    //检查企业信息
    const checkCompany = () => {
      var companyInfo = _this.companyInfo;
      proxy.$post('company/checkCompany', {
        company_name: companyInfo.company_name,
      }).then(res => {
        var companyInfo = res.data;
        // console.log(companyInfo.length)
        if (companyInfo.id > 0) {
          updateCompany(companyInfo)
        }
      })
    }
    //检查公司简称
    const checkShort = (v) => {
      if (/^[A-Z]{1,4}$/.test(v) === false) {
        Api.msg('请输入1到4位大写英文字符', 'error', 25);
        let res = v.match(/([A-Z]){1,4}/)
        let str = ''
        if (res != null) str = res[0]
        _this.companyInfo.brief_name = str || ''
      }
    }
    const previewImg = (url) => {
      layer.open({
        title:'图片预览',
        type: 2,
        content: url,
        area: ['60%', '80%'],
      })
    }
    //==================================================================
    // 文件上传前触发
    const handleBeforeUpload = () => {
      const flag = ref(true);
      fileList.value.forEach((item) => {
        //const isImg = /^image\/(jpeg|png|jpg)$/.test(item.type);
        const allowType = 'jpg,jpeg,png,gif';
        const type = item.name.split(".")[1];
        //const fileType = item.name.substring(item.name.lastIndexOf('.') + 1)
        if (allowType.indexOf(type) == -1) {
          Api.msg('只能上传图片！', 'error', 25)
          flag.value = false;
          return;
        } else if (item.size / 1024 / 1024 > 2) {
          Api.msg('上传图片大小不能超过 2MB!', 'error', 25);
          flag.value = false;
          return;
        }
      });
      return flag.value;
    }
    // 图片上传前预览
    function handlePictureCardPreview(file) {
      _this.license = file.url;
      layer.open({
        title:'图片预览',
        type: 2,
        content: _this.license,
        area: ['60%', '80%'],
      })
    }
    // 图片删除
    /*function handleRemove(file) {
      console.log(file, _this.license);
      _this.license = ''
    }*/
    const handleRemove = () => {
      upload_btn_flag.value = false;
    }
    // 文件超出上传限制 此方法是和:limit绑定的
    // 我这里是limit=1,也就是限制只能传一个文件，当超出这个范围时就会触发这个函数
    const handleExceed = () => {
      Api.msg('只能上传一个文件，如有需要请删除上一个文件再进行上传', 'error', 25);
    }

    /**
     * 文件选择改变时触发
     */
    function handleChange(file,fileListVal) {
      fileList.value = fileListVal;
      if (fileList.value.length > 0) {
        upload_btn_flag.value = true;
      }
    }

    /**
     * 提交请求
     * 处理上传逻辑
     */
    async function upload_submit() {
      if (fileList.value.length === 0) {
        return Api.msg('请选择要上传的文件', 'error', 25);
      }
      const flag = handleBeforeUpload();
      if (!flag) {
        return;
      }
      const formData = new FormData();
      fileList.value.forEach((file) => {
        formData.append('file', file.raw);
        formData.file = file.raw;
      });
      formData.append('id', 0);
      formData.append('ptype', 'license');
      formData.append('old_pic', _this.companyInfo.license);
      upload_loading_btn_flag.value = true;
      upload_btn_flag.value = false;
      try {
        const response = await fetch(Api.UPLOAD_URL, {
          method: 'POST',
          body: formData,
          headers: _this.headers,
        });
        if (response.ok) {
          const data = await response.json();
          _this.companyInfo.license = data.data.src;
          upload_btn_flag.value = false;
          upload_loading_btn_flag.value = false;
          uploadRef.value.clearFiles();
        } else {
          Api.msg('文件上传失败', 'error', 25);
        }
      } catch (error) {
        Api.msg( error.message || '文件上传失败', 'error', 25);
      }
    }
    //======================================================================================
    // 是否显示 简介文本域
    const fun_show = ()=>{
      if (_this.companyInfo.brief_id==99) {
        show_flag.value = true;
      }else {
        show_flag.value = false;
      }
      if (_this.companyInfo.brief_id==99 || _this.companyInfo.brief_id==0){
        dialog_flag.value = false;
      }else{
        dialog_flag.value = true;
      }
    }

    //切换企业方针
    const changePolicy = (e) => {
      _this.quality_policy = e;
      _this.companyInfo.quality_policy = e;
    }
    //切换企业简介
    const changeBrief = (e) => {
      _this.companyInfo.brief = e
    }
    //切换质量目标
    const changeQuality = (e) => {
      _this.companyInfo.quality_target = e
    }
    // 预览企业简介
    const openBriefDialog = (id) =>{
      var load = Api.loading()
      proxy.$post("/company/show_brief", {
        brief_id: id
      }).then((response) => {
        if (response.code == 0) {
          company_brief.value = response.info;
        }else {
          Api.msg(response.msg, 'error', 25);
        }
        Api.close(load);
      })
      briefDialogVisible.value = true;
    }

    // 产品删除
    const deleteTr = (index)=>{
      if (index!==0){
        _this.companyInfo.productList.splice(index, 1);
      }
    }
    //产品添加
    const addTr = (index)=>{
      var newTr = {name:''}
      _this.companyInfo.productList.splice(index+1, 0, newTr);
    }
    // 产品管理
    const addProduct = () => {
      visibleDialog.value.dialogVisble = true;
      visibleDialog.value.product_tags = _this.companyInfo.product_list;
    }
    // 设置产品列表
    const setProduct = (param) => {
      let containsObject = _this.companyInfo.product_list.some(item =>item === param);
      if(!containsObject) {
        _this.companyInfo.product_list.push(param);
      }
    }
    // 删除产品列表
    const delProduct = (param) => {
      let arr = _this.companyInfo.product_list.filter(item => item !== param);
      _this.companyInfo.product_list = arr;
    }

    return {
      ...toRefs(_this),
      getCompanyInfo,product_flag,
      modifyCompany,
      checkCompany,
      changePolicy,
      changeBrief,
      changeQuality,
      updateCompany,
      changeArea,
      changeIndustrys,
      resetPercent,
      checkShort,
      province_id,
      city_id,
      country_id,
      readonly,
      industrys_pid,
      industrys_id,
      previewImg,fun_show,show_flag,upload_btn_flag,uploadRef,upload_loading_btn_flag,
      handleBeforeUpload,handlePictureCardPreview,handleChange,handleRemove,handleExceed,upload_submit,
      openBriefDialog,dialog_flag,briefDialogVisible,company_brief,product_percent_options,satisfaction_options,
      deleteTr,addTr,addProduct,visibleDialog,setProduct,delProduct
    }
  }
}
</script>

<style scoped>
/* 中间部分 */
form {
  margin-top: 24px;
  margin-bottom: 24px;
}

form > .flex + div {
  margin-top: 16px;
}

.input {
  background: #F5F6FA;
  border-radius: 4px;
  height: 36px;
  line-height: 36px;
}

.input + div {
  margin-left: 12px;
  background: rgba(48, 97, 242, .1);
  color: var(--color-text-blue);
  font-size: 16px;
  width: 5em;
  cursor: pointer;
}

.input + div i {
  display: inline-block;
  transition: transform .3s;
}

.trans180 {
  transform: rotate(180deg);
  transition: transform .3s;
}

.input .el-select {
  width: 100px;
  height: 24px;
  line-height: 24px;
  border-right: 1px solid #9D9FA6;
  margin-top: -5px;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  --el-select-border-color-hover: transparent;
}

.input :deep(.el-input) {
  line-height: 24px;
  height: 24px;
  border-color: transparent;
}

.input :deep(.el-input__wrapper) {
  background: transparent;
  box-shadow: none;
}

.input :deep(.el-select .el-input.is-focus .el-input__wrapper),
.input :deep(.el-select .el-input__wrapper.is-focus) {
  box-shadow: none !important;
}

.input input {
  height: 36px;
  line-height: 36px;
  border: none;
  background: transparent;
  padding-left: 1.19vw;
}

.input i {
  width: 50px;
  height: 36px;
  font-size: 22px;
  background: var(--color-text-blue);
  color: #fff;
  text-align: center;
  display: inline-block;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

button {
  width: 64px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  border: 0;
  margin-left: 8px;
  cursor: pointer;
}

button[type='submit'] {
  background: var(--color-text-blue);
  color: #fff;
}

button[type='reset'] {
  background: #F5F6FA;
  color: #161823;
}

.prudence {
  background: #E1EDFA;
  border: 1px solid #CFD2E6;
  border-radius: 4px;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  margin: 15px 0;
}

ul {
  height: 420px;
  overflow: hidden;
  margin: 15px 0;
}

li {
  background: #F5F6FA;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0;
  padding: 0 6px 0 18px;
  box-sizing: border-box;
}

.tit {
  width: 65%;
  font-size: 16px;
  font-family: 'PingFang-SC-Medium';
}

.num {
  width: calc(35% - 48px);
  font-size: 14px;
  font-family: 'PingFang-SC-Medium';
}

.addbtn {
  background: var(--color-text-blue);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 4px;
  margin-top: 6px;
  margin-left: 5px;
}

.picked .addbtn {
  background: rgba(48, 97, 242, .2);
}

.flex-b-c > span {
  width: 100px;
  height: 28px;
  line-height: 28px;
  background: var(--color-text-blue);
  color: #fff;
  font-size: 18px;
  font-family: 'PingFang-SC-Bold';
  text-align: center;
  border-radius: 4px;
  cursor: default;
}

/* 下 */
.foot_msg button {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: 'PingFang-SC-Bold';
  color: var(--color-text-white);
  background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
  border-radius: 4px;
  border: none;
  width: 200px;
  margin: calc((4.1vw - 40px) / 2) 0;
}

/* 弹出框 */
ol {
  margin: 15px 0;
}

.cont li.picked .addbtn {
  background: var(--color-text-blue);
}

.blue.hand {
  border: 0;
  background: none;
}

.btn.cancle {
  background: transparent;
  color: #9D9FA6;
  font-size: 16px;
}

.btn {
  background: var(--color-text-blue);
  color: #fff;
  width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
}

.flex > span {
  font-size: 14px;
  color: #6D6E73;
  margin-right: 10px;
  line-height: 32px;
}

/* uploader上传图片 样式重置 */
.avatar-uploader :deep(.el-upload) {
  background: #fff;
  border: 1px dashed #CFD2E6;
  width: 8.29vw;
  height: 5.58vw;
  margin-right: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.el-form-item__content .bg_56a {
  background: none;
}

.avatar-uploader :deep(.el-upload):hover {
  border-color: #409EFF;
}

.icon-xiangji {
  color: var(--color-text-blue);
  font-size: 30px;
}

.avatar {
  width: 7.29vw;
  height: 4.58vw;
  display: block;
}
</style>
