<template>
  <el-dialog v-model="dialogVisble" title="管理产品" width="600">
    <el-tabs class="demo-tabs">
      <el-tab-pane label="手动添加产品">
        <el-form label-width="80px">
          <el-form-item label="产品名称">
            <div class="flex">
              <div class="input">
                <el-input v-model="product_name" placeholder="请输入产品名称"></el-input>
              </div>
                <el-button type="primary" @click="addProduct">添 加</el-button>
            </div>
          </el-form-item>
        </el-form>
        <el-tag v-for="tag in product_tags" :key="tag" closable type="primary" style="margin: 5px; font-size: 14px;" @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
      </el-tab-pane>
      <el-tab-pane label="从成品库选择">
        <div>
          <!-- 搜索输入框 -->
          <el-form ref="formScreening" @submit.native.prevent>
            <div class="flex">
              <div class="input">
                <el-input type="text" v-model="search_word" placeholder="输入要查找的产品名称" />
              </div>
              <el-button type="primary" @click="searchProduct">搜 索</el-button>
            </div>
          </el-form>
          <!-- 成品列表 -->
          <el-table v-if="finishedProduct.length>0" :data="finishedProduct" border show-header style="margin-top: 15px; width: 100%;">
            <el-table-column label="名称" width="350" align="center">
              <template #default="scope">
                {{scope.row.product_name}}
              </template>
            </el-table-column>
            <el-table-column prop="age" fixed="right" label="操作" width="100" align="center">
              <template #default="scope">
                <div class="addbtn" v-show="scope.row.is_add" @click="selectProduct(scope.row)" title="添加产品"><i class="icon-tianjia"></i></div>
                <div class="delbtn" v-show="scope.row.is_del" @click="deleteProduct(scope.row)" title="删除产品"><i class="icon-jianqu"></i></div>
              </template>
            </el-table-column>
          </el-table>
          <el-empty v-else description="暂无产品信息！"></el-empty>
          <!-- 分页控制 -->
          <el-pagination v-if="finishedProduct.length>0" @current-change="handleCurrentChange"
                         :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next" :total="allCount">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import {ref, onMounted, getCurrentInstance, watch} from 'vue';
import finished from "@element-plus/icons/lib/Finished";

export default {
  computed: {
    finished() {
      return finished
    }
  },
  props: {
    visibleDialog: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const {proxy} = getCurrentInstance()
    const search_word = ref('');
    const currentPage = ref(1);
    const pageSize = ref(10);
    const finishedProduct = ref([]);
    const dialogVisble = ref(false);
    const allCount = finishedProduct.value.length;
    const product_tags = ref([])
    const product_name = ref('');

    onMounted(()=>{
    });
    // 获取成品列表
    async function searchProduct() {
      proxy.$post("/company/getFinishedProduct",{
        search_word:search_word.value,
        page:currentPage.value,
      }).then((res)=>{
        finishedProduct.value = res.data.data;
      });
    }

    function selectProduct(param) {
      if (product_tags.value.indexOf(param.product_name) === -1 && param.product_name !== '') {
        product_tags.value.push(param.product_name);
        emit('setProduct', param.product_name);
      } else {
        proxy.$message({
          type: 'warning',
          message: '产品已存在' + product_name.value
        });
      }
      for (let i = 0; i < finishedProduct.value.length; i++) {
        if (finishedProduct.value[i].id === param.id) {
          finishedProduct.value[i].is_add = false;
          finishedProduct.value[i].is_del = true;
        }
      }
      //emit('setProduct', param.product_name);
    }

    function deleteProduct(param) {
      for (let i = 0; i < finishedProduct.value.length; i++) {
        if (finishedProduct.value[i].id === param.id) {
          finishedProduct.value[i].is_add = true;
          finishedProduct.value[i].is_del = false;
        }
      }
      emit('delProduct', param.product_name);
      product_tags.value.splice(product_tags.value.indexOf(param.product_name), 1);
    }

    //切换页码
    const handleCurrentChange = (page)=>{
      currentPage.value = page;
      searchProduct();
    }

    watch(dialogVisble, () => {
      searchProduct();
    });

    // 添加
    const addProduct = () => {
      if (product_tags.value.indexOf(product_name.value) === -1 && product_name.value !== '') {
        product_tags.value.push(product_name.value);
        emit('setProduct', product_name.value);
      } else {
        proxy.$message({
          type: 'warning',
          message: '产品已存在' + product_name.value
        });
      }
    }
    // 删除
    const handleClose = (tag) => {
      product_tags.value.splice(product_tags.value.indexOf(tag), 1);
      emit('delProduct', tag);
    }
    return {
      search_word, currentPage, pageSize,allCount,handleCurrentChange,
      finishedProduct, dialogVisble,
      product_name,product_tags,handleClose,addProduct,
      searchProduct,
      selectProduct,deleteProduct,
    };
  }
};
</script>

<style scoped>
.input :deep(.el-input) {
  background: #F5F6FA;
  border-radius: 4px;
  height: 32px;
  line-height: 32px;
  width: 200px;
  border: 1px solid #9D9FA6;
  margin-right: 3px;
}
:deep(.el-input__wrapper){
  /*border:1px solid #9D9FA6;*/
  background:transparent;
  box-shadow:none;
}
:deep(.el-input.is-focus .el-input__wrapper),
:deep(.el-input__wrapper.is-focus){
  box-shadow:none !important;
}
.addbtn{
  background: var(--color-text-blue);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius:4px;
  margin-top:6px;
  margin-left:5px;
  cursor:pointer;
}
.picked .addbtn{
  background: rgba(48, 97, 242, .2);
}
.delbtn{
  background: var(--color-text-red);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius:4px;
  margin-top:6px;
  margin-left:5px;
  cursor:pointer;
}
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
