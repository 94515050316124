<!-- 出厂检验 -->
<template>
  <RightSide>
    <template v-slot:tit>出厂检验</template>
    <template v-slot:write_msg>
      <div class="flex">
        <div class="text" style="width: calc(100% - 4em);padding-top: 10px;">
          <el-table :data="productive_task_data" border show-header>
            <el-table-column prop="product_name" label="产品名称" width="150" align="center" />
            <el-table-column prop="specifications" label="规格型号" width="100" align="center" />
            <el-table-column prop="product_num" label="生产数量" width="100" align="center" />
            <el-table-column prop="product_date" label="生产日期" width="120" align="center" />
            <el-table-column prop="delivery_date" label="交货日期" width="120" align="center" />
            <el-table-column fixed="right" label="操作" width="150" align="center">
              <template #default="scope">
                <el-button link type="primary" @click="handleInspect(scope.row)">检验</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="f14 red"></div>
        </div>
      </div>
      <!--弹窗-->
      <el-dialog v-model="dialogFormVisible" title="出厂检验管理" width="500" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form ref="formRef" :model="productive_task_form" label-width="100px" style="padding-right:30px;">
          <el-form-item label="产品名称：">
            <el-select v-model="productive_task_form.product_name" placeholder="请选择产品" style="width: 200px;">
              <el-option value="">请选择</el-option>
              <el-option v-for="item in product_list" :key="item" :label="item" :value="item"></el-option>
            </el-select>
            <div class="red">&nbsp;*</div>
          </el-form-item>
          <el-form-item label="规格型号">
            <el-input v-model="productive_task_form.specifications" placeholder="规格型号" autocomplete="off" style="width: 150px;"/>
            <div class="red">&nbsp;*</div>
          </el-form-item>
          <el-form-item label="生产数量">
            <el-input v-model="productive_task_form.product_num" placeholder="生产数量" autocomplete="off" style="width: 150px;"/>
            <div class="red">&nbsp;*</div>
          </el-form-item>
          <el-form-item  label="生产日期">
            <el-date-picker
                v-model="productive_task_form.product_date"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item  label="交货日期">
            <el-date-picker
                v-model="productive_task_form.delivery_date"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </span>
        </template>
      </el-dialog>
      <!--检验对话框-->
      <InspectionDialog ref="inspectionDialogVisible" @close="resetPercent();"></InspectionDialog>
    </template>

<!--    <template v-slot:foot_msg>
      <button @click="saveEquipment" style="float:right;width:100px;">保 存</button>
    </template>-->
  </RightSide>
</template>
<script>
import {reactive, toRefs, ref, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'
import InspectionDialog from "@/components/common/InspectionDialog.vue";

export default {
  name: 'DeliveryInspection',
  components: {
    InspectionDialog,
    RightSide,
  },
  props: {},
  setup(props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    //公共数据
    const dialogFormVisible = ref(false);
    const productive_task_form = ref({});
    let _this = reactive({
      site: Api.WEB_SITE,
      productive_task_data: [],
      product_list:[],//产品列表
    });
    //检验组件
    const inspectionDialogVisible = ref(false);
    onMounted(() => {
      getProductiveTaskList();
    })
    //获取成品信息
    const getProductiveTaskList = () => {
      proxy.$post('company/getProductiveTaskList').then(res => {
        if (res.code == 0) {
          _this.productive_task_data = res.data;
        }else {
          Api.msg(res.msg);
        }
      });
    }
    // 获取产品列表
    const getProductList = () => {
      proxy.$post('project/getProductList', {}).then(res => {
        if (res.code == 0) {
          _this.product_list = res.data || []
        } else {
          Api.alert(res.msg)
        }
      });
    }
    //添加
    const handleAdd = (product_name)=>{
      //重置表单
      productive_task_form.value = {};
      getProductList();
      dialogFormVisible.value = true;
      productive_task_form.value.product_name = product_name;
    }
    //编辑
    const handleEdit = (row)=>{
      productive_task_form.value = row;
      dialogFormVisible.value = true;
    }
    //删除
    const handleDel = (row)=>{
      console.log(row)
      Api.confirm('是否删除此记录?',()=>{
        proxy.$post('company/taskDataDel',{
          id:row.id,
        }).then((res)=>{
          if(res.code==0||res.code==200){
            getProductiveTaskList();
          }
          Api.msg(res.msg);
        });
      });
    }
    //保存
    const handleSave = () => {
      proxy.$post('company/taskDataSave', productive_task_form.value).then((res)=>{
        if(res.code==0||res.code==200){
          getProductiveTaskList();
          dialogFormVisible.value = false;
        }
        Api.msg(res.msg);
      });
    }
    //检验
    const handleInspect = (row) => {
      inspectionDialogVisible.value.dialogVisible = true;
      proxy.$post('company/getFinishedProductProject', {task_id:row.task_id,product_name:row.product_name}).then(res => {
        if (res.code == 0) {
          inspectionDialogVisible.value.finished_product_form = res.data;
        }else {
          inspectionDialogVisible.value.finished_product_form = row;
        }
      });
    }

    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -11;
      context.emit("updatePercent", data)
    }
    return {
      ...toRefs(_this),dialogFormVisible,productive_task_form,inspectionDialogVisible,
      handleSave,handleAdd,handleEdit,handleDel,getProductList,handleInspect,
      resetPercent,getProductiveTaskList
    }
  }
}
</script>

<style scoped>
/* 中间部分 */
form {
  margin-top: 24px;
  margin-bottom: 24px;
}

form > .flex + div {
  margin-top: 16px;
}
.prudence{
  background: #E1EDFA;
  border: 1px solid #CFD2E6;
  border-radius: 4px;
  height:36px;
  line-height:36px;
  padding:0 15px;
  margin:15px 0;
}
button{
  background:#3061F2;
  color:#fff;
  font-size:14px;
  border-radius:4px;
  padding:0 14px;
  height:30px;
  line-height: 30px;
  border:none;
  cursor: pointer;
  /*margin-left: 10px;*/
}
button i{
  margin-right:6px;
}
.f14{
  font-size:14px;
}
ul {
  height: 420px;
  overflow: hidden;
  margin: 15px 0;
}

li {
  background: #F5F6FA;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0;
  padding: 0 6px 0 18px;
  box-sizing: border-box;
}

.tit {
  width: 65%;
  font-size: 16px;
  font-family: 'PingFang-SC-Medium';
}

.num {
  width: calc(35% - 48px);
  font-size: 14px;
  font-family: 'PingFang-SC-Medium';
}

.addbtn {
  background: var(--color-text-blue);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 4px;
  margin-top: 6px;
  margin-left: 5px;
}
.addbtn:hover{
  color: #0a84ff !important;
  border-color: var(--color-text-blue);
  border: 1px solid var(--color-text-blue) !important;
}
.addbtn:focus{
  background: var(--color-text-blue) !important;
  color: #fff !important;
  border: 1px solid var(--color-text-blue) !important;
}

.picked .addbtn {
  background: rgba(48, 97, 242, .2);
}

/* 下 */
.foot_msg button {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: 'PingFang-SC-Bold';
  color: var(--color-text-white);
  background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
  border-radius: 4px;
  border: none;
  width: 200px;
  margin: calc((4.1vw - 40px) / 2) 0;
}

/* 弹出框 */
ol {
  margin: 15px 0;
}

.cont li.picked .addbtn {
  background: var(--color-text-blue);
}

.blue.hand {
  border: 0;
  background: none;
}

.flex > span {
  font-size: 14px;
  color: #6D6E73;
  margin-right: 10px;
  line-height: 32px;
}
/* 表格 */
.text{
  width: calc(90% - 4em - 10px);
  font-size: 14px;
}
:deep(.el-table th.el-table__cell){
  background:#eaeffe;
  color:#161823;
  border-color: #DADCE5;
  border-bottom:transparent;
}
:deep(.el-table.is-scrolling-none th.el-table-fixed-column--right){
  background:#eaeffe;
}
:deep(.el-table) input{
  border:none;
  height:25px;
  font-size:14px;
  color:#3D3D40;
}
:deep(.el-table) i+i{
  margin-left:10px;
}
:deep(.el-table) i.red{
  border:1px solid #FE2C55;
  border-radius:50%;
  padding:3px;
  font-size:12px;
}
:deep(.el-table) i.blue{
  border:1px solid #3061F2;
  border-radius:50%;
  padding:3px;
  font-size:12px;
}

:deep(.el-dialog__headerbtn .el-dialog__close){
  color: #ff0000 !important;
  font-size: 20px !important;
}
</style>
