import crypto from "@/utils/crypto";
import {ElMessage, ElMessageBox} from 'element-plus'
import {layer} from "@layui/layer-vue";
import axios from "axios";
import {Promise} from "core-js";

const BASE_URL = process.env.VUE_APP_BASE_URL
const WEB_SITE = process.env.VUE_APP_WEB_SITE
const UPLOAD_URL = process.env.VUE_APP_UPLOAD_URL

/**
 * 固定菜单数组
 */
const fixed_menu = [];
fixed_menu[0] = '/home';
fixed_menu[-1] = '/home/externalList';
fixed_menu[-2] = '/home/department';
fixed_menu[-3] = '/home/viewProject';
fixed_menu[-4] = '/home/flow';
fixed_menu[-5] = '/home/address';
fixed_menu[-6] = '/home/qualification';
fixed_menu[-7] = '/home/equipmentSchedule';
fixed_menu[-8] = '/home/customer';
fixed_menu[-9] = '/home/supplier';
fixed_menu[-10] = '/home/materialSchedule';
fixed_menu[-11] = '/home/deliveryInspection';
fixed_menu[-12] = '/home/productiveTask';
fixed_menu[-13] = '/home/disqualificationRecord';
fixed_menu[-14] = '/home/productionLine';
fixed_menu[-15] = '/home/specialProcess';
/**
 * 基本信息正则验证
 */
function checkPreg(str, type = 'mobile') {
    //手机号
    if (type == 'mobile') {
        return /^1[3456789]\d{9}$/.test(str);
    }
    //邮箱
    if (type == 'email') {
        return /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(str);
    }
    //身份证
    if (type == 'identity') {
        return /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/.test(str)
    }
    // 密码
    if (type == 'password') {
        return /^[a-zA-Z][a-zA-Z0-9]{5,15}/.test(str);
    }
    // 用户名
    if (type == 'username') {
        return /[a-zA-Z0-9]{6,16}/.test(str);
    }
    //公司名称
    if (type == 'company') {
        return /^[\u4e00-\u9fa5（）]+$/.test(str);
    }
    return false;
}

/**
 * 数组去空
 */
function array_filter(arr) {
    var new_arr = [];
    arr.forEach(o => {
        if (o) {
            new_arr.push(o);
        }
    });
    return new_arr;
}

/**
 * 数组去重
 */
function array_unique(arr) {
    var result = [];
    arr.forEach(function (v, i, arr) { //这里利用map，filter方法也可以实现
        var bool = arr.indexOf(v, i + 1); //从传入参数的下一个索引值开始寻找是否存在重复
        if (bool === -1) {
            result.push(v);
        }
    })
    return result;
}

function array_column(arr, key) {
    if (Array.isArray(arr)) {
        let newArr = []
        arr.forEach(v => {
            var it = v[key]
            if (typeof it == 'number') it = it.toString()
            newArr.push(it)
        })
        return newArr
    } else {
        return [];
    }
}

/**
 * 获取随机字符串
 */
function randomString(len) {
    len = len || 32;
    var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    var maxPos = chars.length;
    var pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

/**
 * 去除字符串中的非数字字符
 */
function replaceNonDigits(str) {
    return str.replace(/[^\d]/g, '');
    //return str.replace(/\D/g, '');
}

/**
 * 检测是否为数字
 * @param num
 * @returns {boolean} 数字返回true，非数字返回false
 */
function isNUmber(num) {
    return /^[0-9]+$/.test(num);
}

/**
 * 公共头部
 * @return {[type]} [description]
 */
function getHeader() {
    let timeStamp = storageGet('stamp');
    if (!timeStamp || timeStamp == null || timeStamp == 'undefined') {
        timeStamp = new Date().getTime();
    }
    let user_id = sessionGet('user_id') || 0;
    let project_id = sessionGet('project_id') || 0;
    let userkey = randomString(8) + '-' + timeStamp + '-' + user_id + '-' + project_id;
    let token = md5(userkey + 'zhguoguo4001109001');
    let headers = {
        userKey: userkey,
        token: token,
    };
    return headers;
}

/**
 * 获取服务器时间
 */
function getServerDate() {
    var promise = new Promise((resolve, reject) => {
        var t = storageGet('stamp');
        if (t > 0) {
            resolve(t)
        } else {
            axios.get('/index/get_time', {}).then(res => {
                t = res.data.msg
                storageSet('stamp', t, 10)
                resolve(t)
            }).catch(() => {
                reject(0)
            })
        }
    })
    return promise
}

/**
 * 本地存储+过期时间
 */
function storageSet(key, val, expires = 0) {
    let t = Math.floor(new Date().getTime() / 1000);
    // localStorage.remove(key);
    let exp_time = 0;
    if (expires > 0) {
        exp_time = t + expires;
    }
    let it = crypto.encrypt(JSON.stringify({val: val, expires: exp_time}))
    localStorage.setItem(key, it);//转换成json字符串序列
}

/**
 * 获取本地存储+过期时间
 */
function storageGet(key) {
    let t = Math.floor(new Date().getTime() / 1000);
    try {
        var val = localStorage.getItem(key);//获取存储的元素
        if (!val) return '';
        val = crypto.decrypt(val)
        var dataobj = JSON.parse(val);//解析出json对象
        let expires = 0
        if (typeof dataobj.expires != 'undefined') {
            expires = dataobj.expires || 0
        }
        if (t > expires && expires > 0) {
            return '';
        } else {
            return dataobj.val;
        }
    } catch (error) {
        console.log(error)
        return '';
    }
}

function sessionSet(key, val, expires = 0) {
    let t = Math.floor(new Date().getTime() / 1000);
    // localStorage.remove(key);
    let exp_time = 0;
    if (expires > 0) {
        exp_time = t + expires;
    }
    let it = crypto.encrypt(JSON.stringify({val: val, expires: exp_time}))
    sessionStorage.setItem(key, it);//转换成json字符串序列
}

/**
 * 获取本地存储+过期时间
 */
function sessionGet(key) {
    let t = Math.floor(new Date().getTime() / 1000);
    try {
        var val = sessionStorage.getItem(key);//获取存储的元素
        if (!val) return '';
        val = crypto.decrypt(val)
        var dataobj = JSON.parse(val);//解析出json对象
        let expires = 0
        if (typeof dataobj.expires != 'undefined') {
            expires = dataobj.expires || 0
        }
        if (t > expires && expires > 0) {
            return '';
        } else {
            return dataobj.val;
        }
    } catch (error) {
        console.log(error)
        return '';
    }
}

/**
 * 删除存储
 */
function removeItem(key) {
    localStorage.removeItem(key)
    sessionStorage.removeItem(key)
}

/**
 * 检查登录
 */
function checkLogin() {
    var uid = sessionGet('user_id') || 0
    if (uid > 0) return uid;
    uid = storageGet('user_id') || 0
    if (uid > 0) {
        sessionSet('user_id', uid)
        sessionSet('project_id', storageGet('project_id'))
        return uid
    } else {
        removeItem('user_id')
        removeItem('project_id')
    }
    return 0
}

// 弹窗开始
/**
 * 消息框
 * @param {String} message 消息文字
 * @param {String} type     0,无图标|1笑脸   success/warning/info/error
 * @param {String} offset  位置
 */
function msg(message, type = 0, offset = '', callback = null) {
    if (typeof type == 'number') {
        if (offset == '') offset = 'auto';
        if (offset == 'right') offset = ['45vh', '65vw'];
        if (offset == 'left') offset = ['45vh', '35vw'];
        layer.msg(message, {
            icon: type, //图标 1:一个勾,2:一个叉,3:一个问号,4:一个锁,5:一个哭脸,6:一个笑脸
            time: 3000,
            offset: offset
        }, function (res) {
            if (typeof callback == 'function') callback(res)
        })
    } else {
        if (offset == '') offset = 25;
        ElMessage({
            message: message,
            type: type, //'success' | 'info' | 'warning' | 'error'
            duration: 3000,
            offset: offset,
            showClose: true,
            onClose: function (res) {
                if (typeof callback == 'function') callback(res)
            }
        })
    }
}

/**
 *
 * @param message   消息内容
 * @param title     弹框标题
 * @param type     消息类型，用于图标显示 'success' | 'info' | 'warning' | 'error'
 * @param res       回调函数
 */
function msgbox(message, title='信息提示', type, res=null){
    ElMessageBox.confirm(
        message,
        title,
        {
            confirmButtonText: '关闭',
            showCancelButton: false,
            type: type,
            closeOnClickModal: false,
            closeOnPressEscape: false
        }
    )
        .then(() => {
            if (res==1){
                window.location.reload();
            }
        })
        .catch(() => {

        })
}
/**
 * 确认框
 * @return {[type]} [description]
 */
function confirm(message, success = null, fail = null) {
    ElMessageBox.confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        if (typeof success == 'function') success()
    }).catch(() => {
        if (typeof fail == 'function') fail()
    });
}

/**
 * 获取文件后缀
 */
function get_file_suff(url) {
    var index = url.lastIndexOf(".")
    var ext = url.substring(index + 1)
    return ext
}

// 加载中...
function loading(type = 0) {
    var load = layer.load(type)
    return load;
}

//关闭弹窗
function close(t) {
    layer.close(t)
}

/*@bief md5加密
 */
function md5(string) {
    function md5_RotateLeft(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    }

    function md5_AddUnsigned(lX, lY) {
        var lX4, lY4, lX8, lY8, lResult;
        lX8 = (lX & 0x80000000);
        lY8 = (lY & 0x80000000);
        lX4 = (lX & 0x40000000);
        lY4 = (lY & 0x40000000);
        lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
        if (lX4 & lY4) {
            return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
        }
        if (lX4 | lY4) {
            if (lResult & 0x40000000) {
                return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
            } else {
                return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
            }
        } else {
            return (lResult ^ lX8 ^ lY8);
        }
    }

    function md5_F(x, y, z) {
        return (x & y) | ((~x) & z);
    }

    function md5_G(x, y, z) {
        return (x & z) | (y & (~z));
    }

    function md5_H(x, y, z) {
        return (x ^ y ^ z);
    }

    function md5_I(x, y, z) {
        return (y ^ (x | (~z)));
    }

    function md5_FF(a, b, c, d, x, s, ac) {
        a = md5_AddUnsigned(a, md5_AddUnsigned(md5_AddUnsigned(md5_F(b, c, d), x), ac));
        return md5_AddUnsigned(md5_RotateLeft(a, s), b);
    }

    function md5_GG(a, b, c, d, x, s, ac) {
        a = md5_AddUnsigned(a, md5_AddUnsigned(md5_AddUnsigned(md5_G(b, c, d), x), ac));
        return md5_AddUnsigned(md5_RotateLeft(a, s), b);
    }

    function md5_HH(a, b, c, d, x, s, ac) {
        a = md5_AddUnsigned(a, md5_AddUnsigned(md5_AddUnsigned(md5_H(b, c, d), x), ac));
        return md5_AddUnsigned(md5_RotateLeft(a, s), b);
    }

    function md5_II(a, b, c, d, x, s, ac) {
        a = md5_AddUnsigned(a, md5_AddUnsigned(md5_AddUnsigned(md5_I(b, c, d), x), ac));
        return md5_AddUnsigned(md5_RotateLeft(a, s), b);
    }

    function md5_ConvertToWordArray(string) {
        var lWordCount;
        var lMessageLength = string.length;
        var lNumberOfWords_temp1 = lMessageLength + 8;
        var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
        var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
        var lWordArray = Array(lNumberOfWords - 1);
        var lBytePosition = 0;
        var lByteCount = 0;
        while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - (lByteCount % 4)) / 4;
            lBytePosition = (lByteCount % 4) * 8;
            lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
            lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
    }

    function md5_WordToHex(lValue) {
        var WordToHexValue = "", WordToHexValue_temp = "", lByte, lCount;
        for (lCount = 0; lCount <= 3; lCount++) {
            lByte = (lValue >>> (lCount * 8)) & 255;
            WordToHexValue_temp = "0" + lByte.toString(16);
            WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
        }
        return WordToHexValue;
    }

    function md5_Utf8Encode(string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }
        return utftext;
    }

    var x = Array();
    var k, AA, BB, CC, DD, a, b, c, d;
    var S11 = 7, S12 = 12, S13 = 17, S14 = 22;
    var S21 = 5, S22 = 9, S23 = 14, S24 = 20;
    var S31 = 4, S32 = 11, S33 = 16, S34 = 23;
    var S41 = 6, S42 = 10, S43 = 15, S44 = 21;
    string = md5_Utf8Encode(string);
    x = md5_ConvertToWordArray(string);
    a = 0x67452301;
    b = 0xEFCDAB89;
    c = 0x98BADCFE;
    d = 0x10325476;
    for (k = 0; k < x.length; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = md5_FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
        d = md5_FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
        c = md5_FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
        b = md5_FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
        a = md5_FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
        d = md5_FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
        c = md5_FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
        b = md5_FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
        a = md5_FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
        d = md5_FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
        c = md5_FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
        b = md5_FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
        a = md5_FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
        d = md5_FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
        c = md5_FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
        b = md5_FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
        a = md5_GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
        d = md5_GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
        c = md5_GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
        b = md5_GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
        a = md5_GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
        d = md5_GG(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = md5_GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
        b = md5_GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
        a = md5_GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
        d = md5_GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
        c = md5_GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
        b = md5_GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
        a = md5_GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
        d = md5_GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
        c = md5_GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
        b = md5_GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
        a = md5_HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
        d = md5_HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
        c = md5_HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
        b = md5_HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
        a = md5_HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
        d = md5_HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
        c = md5_HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
        b = md5_HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
        a = md5_HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
        d = md5_HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
        c = md5_HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
        b = md5_HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
        a = md5_HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
        d = md5_HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
        c = md5_HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
        b = md5_HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
        a = md5_II(a, b, c, d, x[k + 0], S41, 0xF4292244);
        d = md5_II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
        c = md5_II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
        b = md5_II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
        a = md5_II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
        d = md5_II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
        c = md5_II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
        b = md5_II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
        a = md5_II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
        d = md5_II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
        c = md5_II(c, d, a, b, x[k + 6], S43, 0xA3014314);
        b = md5_II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
        a = md5_II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
        d = md5_II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
        c = md5_II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
        b = md5_II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
        a = md5_AddUnsigned(a, AA);
        b = md5_AddUnsigned(b, BB);
        c = md5_AddUnsigned(c, CC);
        d = md5_AddUnsigned(d, DD);
    }
    return (md5_WordToHex(a) + md5_WordToHex(b) + md5_WordToHex(c) + md5_WordToHex(d)).toLowerCase();
}

export default {
    WEB_SITE,
    BASE_URL,
    UPLOAD_URL,
    checkPreg,
    array_filter,
    array_unique,
    array_column,
    getHeader,
    md5,
    randomString,
    getServerDate,
    storageSet,
    storageGet,
    sessionSet,
    sessionGet,
    removeItem,
    checkLogin,
    msg,
    msgbox,
    confirm,
    get_file_suff,
    loading,
    close,
    fixed_menu,
    replaceNonDigits,
    isNUmber
}
