<!--校验组件-->
<template>
  <el-dialog v-model="dialogVisible" title="成品检验记录" width="800">
  <div style="padding: 10px">
    <el-form ref="formRef" :model="finished_product_form" label-width="100px" style="padding-right:30px;">
      <el-form-item label="产品名称：" style="font-weight: bold;">{{finished_product_form.product_name}}</el-form-item>
      <el-form-item label="规格型号：">
        {{finished_product_form.specifications}}
      </el-form-item>
      <el-form-item label="生产数量：">
        {{finished_product_form.product_num}}
      </el-form-item>
      <el-form-item label="检验数量：">
        <el-input v-model="finished_product_form.inspection_num" placeholder="检验数量" autocomplete="off" style="width: 150px;"/>
        <div class="red">&nbsp;*</div>
      </el-form-item>
      <el-form-item label="检验方式：">
        <el-input v-model="finished_product_form.inspection_method" placeholder="检验方式" autocomplete="off" style="width: 150px;"/>
        <div class="red">&nbsp;*</div>
      </el-form-item>
      <el-form-item label="项目要求">
        <el-button type="primary" @click="addTr()"><i class="icon-tianjia" style="margin-right: 6px;"></i>添加检验项目</el-button>
        <el-table :data="finished_product_form.project_requirement" border show-header style="margin-top: 5px;">
          <el-table-column label="检验项目" width="100" align="center">
            <template #default="scope">
              <el-input v-model="scope.row.project" placeholder="检验项目"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="标准要求" width="300" align="center">
            <template #default="scope">
              <el-input v-model="scope.row.requirement" type="textarea" rows="2" placeholder="请输入标准要求"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="检验结果" width="100" align="center">
            <template #default="scope">
              <el-input v-model="scope.row.inspection_result" placeholder="检验结果"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="是否合格" width="120" align="center">
            <template #default="scope">
              <el-select v-model="scope.row.inspection_quality" placeholder="请选择">
                <el-option label="合格" value="合格"></el-option>
                <el-option label="不合格" value="不合格"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="60" align="center">
            <template #default="scope">
              <i class="red icon-jianqu" @click="deleteTr(scope.$index)"></i>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item  label="检验日期：">
        <el-date-picker
            v-model="finished_product_form.inspection_date"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div style="text-align: center;">
      <el-button type="info" @click="dialogVisible=false" style="margin-right: 15px;">取消</el-button>
      <el-button type="primary" @click="handleSave">保存</el-button>
    </div>
  </div>
  </el-dialog>
</template>
<script>
import {getCurrentInstance, onMounted, ref} from 'vue'
export default {
  props: {
    inspectionDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const {proxy} = getCurrentInstance();
    const Api = proxy.$commonApi;
    const dialogVisible = ref(false);
    const finished_product_form = ref({});
    let taskInfo = ref({});

    onMounted(()=>{
    });

    //保存
    const handleSave = () => {
      proxy.$post('company/inspectionDataSave', finished_product_form.value).then((res)=>{
        if(res.code==0||res.code==200){
          dialogVisible.value=false;
        }
        Api.msg(res.msg);
      });
    }
    //删除行
    const deleteTr = (index)=>{
      finished_product_form.value.project_requirement.splice(index, 1)
    }
    //添加行
    const addTr = ()=>{
      var newTr = {project:'', requirement:'', inspection_result:''}
      if (typeof finished_product_form.value.project_requirement == 'undefined') {
        finished_product_form.value.project_requirement = [newTr];
      }else {
        finished_product_form.value.project_requirement.splice(finished_product_form.value.project_requirement.length+1, 0, newTr)
      }
    }
    // 关闭弹窗时触发的事件
    const handleClose = () => {
      //ctx.emit("getParentList");
    };
    // 禁用日期函数
    const disabledDate = (time) => {
      const startDate = new Date(finished_product_form.value.product_date*1000);
      const endDate = new Date(finished_product_form.value.delivery_date*1000);
      return startDate >= time.getTime() || time.getTime() >= endDate;
    };
    return {
      dialogVisible,taskInfo,finished_product_form,
      addTr,deleteTr,handleSave,handleClose,disabledDate,
    }
  }
}
</script>

<style scoped>

</style>
