<!-- 组织结构 -->
<template>
  <RightSide>
    <template v-slot:tit>流程管理</template>
    <template v-slot:write_msg>
      <el-form ref="formScreening" label-width="80px" @submit.native.prevent>
        <div class="flex-b-c">
          <div class="input">
            <input v-model="keyword" placeholder="请输入流程图名称" @keyup.enter.native="search"/><i class="icon-icon-" @click="search"></i>
          </div>
          <div class="btns">
            <button type="button" @click="back"><i class="icon-shangchuan"></i>我的流程图({{flowList.length}})</button>
<!--            <button type="button" @click="changeNave(3)"><i class="icon-shangchuan"></i>上传流程图</button>-->
            <button type="button" @click="changeNave(1)"><i class="icon-tianjia"></i>自建流程图</button>
          </div>
        </div>
      </el-form>
      <!-- 已添加流程图 -->
      <!-- 我的流程图 -->
      <div v-if="current_nav==0">
        <ul v-if="flowList.length>0" style="min-height: 300px;">
          <li v-for="(item, index) in flowList" :key="index" :class="item.picked?'on':''">
            <div class="text flex">
              <div class="tit line1" @click="spreadFlow(item.id)">{{item.flow_name}}的工艺流程图</div>
              <div class="addbtn" v-if="item.is_edit==1" @click="editFlow(item)">编辑</div>
              <div class="addbtn" v-else-if="item.is_edit==0 && item.type==0" @click="editSystemFlow(item)">编辑</div>
              <div class="addbtn" style="background: red;" @click="delFlow(item.id)">删除</div>
            </div>
            <div class="img" v-show="spread_id==item.id" style="position: relative;">
              <div class="img" v-show="spread_id==item.id" >
                <div style="position: relative;">
                  <img :src="site+item.path+'&random='+Math.random()" />
                </div>
              </div>
            </div>
          </li>
        </ul>
        <el-empty v-else description="暂无流程信息！"></el-empty>
      </div>
      <!-- 上传页面 -->
      <div v-if="current_nav==3">
        <el-form ref="formScreening" label-width="80px">
          <div class="flex">
            <div class="flex--c">
              <el-upload class="avatar-uploader"
                :action="action"
                :headers="getHeader()"
                :show-file-list="false"
                :multiple="true"
                :on-success="handleAvatarSuccess"
              >
                <el-button size="small" type="primary" style="width:auto;">点击上传</el-button>
              </el-upload>
            </div>
          </div>
        </el-form>
        <ul v-if="flowImgList.length>0">
          <li v-for="(item, index) in flowImgList" :key="index" class="flex" style="align-items: center;background: #F5F6FA;padding: 10px 20px">
            <div class="tit line1">
              <el-image style="width: 100px; height: 100px" :src="site+item.path" :preview-src-list="[site+item.path]" lazy></el-image>
            </div>
            <div class="num line1">
              <el-input v-model="item.name"></el-input>
            </div>
            <el-button size="small" type="primary" style="width:auto;" @click="delFlow(index)">
              <el-icon style="vertical-align: middle;"><Delete/></el-icon>
            </el-button>
          </li>
        </ul>
        <el-empty v-else description="暂无上传流程信息！"></el-empty>
      </div>
      <!-- 自建流程图 -->
      <div v-if="current_nav==1">
      <el-form ref="formScreening" label-width="80px">
        <div class="input" style="height: 40px; margin-bottom: 5px; padding-left: 5px;">
          <span class="f14">选择产品：</span>
          <el-select v-model="product_value" placeholder="请选择产品" style="width: 200px;">
            <el-option value="">请选择</el-option>
            <el-option v-for="item in product_list" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-button size="small" @click="addProduct();" style="line-height: 30px; height:30px; margin-left: 15px;">添加</el-button>
        </div>
        <div class="input" style="line-height: 35px;height: 35px;">
          <el-input-tag v-model="product_info" tag-type="" tag-effect="plain" clearable placeholder="请添加关联产品" />
        </div>
        <el-table :data="flowInfo" ref="multipleTable" border show-header>
          <el-table-column label="步骤" width="54" align="center">
            <template #default="scope">{{(scope.$index)+1}}</template>
          </el-table-column>
          <el-table-column label="流程信息" align="center">
              <template #default="scope">
                <el-row>
                  <el-col :span="5" style="text-align: center; font-weight: bold;">流程名称</el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="10" style="text-align: center; font-weight: bold;">流程说明</el-col>
<!--                  <el-col :span="10" style="text-align: center; font-weight: bold;">技术要求</el-col>-->
                </el-row>
                <el-row>
                  <el-col :span="5" style="padding: 0 3px;"><el-input v-model="scope.row.name" placeholder="流程名称" type="textarea"></el-input></el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="10" style="padding: 0 3px;"><el-input v-model="scope.row.content" :rows="2" type="textarea" placeholder="请输入流程说明"></el-input></el-col>
<!--                  <el-col :span="10" style="padding: 0 3px;"><el-input v-model="scope.row.technical_requirement" :rows="2" type="textarea" placeholder="请输入技术要求"></el-input></el-col>-->
                </el-row>
                <el-row>
                  <el-col style="padding: 5px 5px 5px 10px; text-align: left;">
                    <el-checkbox v-model="scope.row.is_out">外包</el-checkbox>
                    <el-checkbox v-model="scope.row.is_special">特殊</el-checkbox>
                    <el-checkbox v-model="scope.row.is_key">关键</el-checkbox>
                    <el-checkbox v-model="scope.row.is_affirm">需确认</el-checkbox>
                    <el-checkbox v-model="scope.row.is_need">焊接</el-checkbox>
                    <el-checkbox v-model="scope.row.is_line">生产流程</el-checkbox>
                  </el-col>
                </el-row>
              </template>
          </el-table-column>

          <el-table-column prop="operation" fixed="right" label="操作" width="150" align="center">
            <template #default="scope">
              <i class="icon-xiangshang hand" @click="moveUp(scope.$index)"></i>
              <i class="icon-xiangxia hand" @click="moveDown(scope.$index)"></i>
              <i class="red icon-jianqu hand" @click="deleteTr(scope.$index)"></i>
              <i class="blue icon-tianjia hand" @click="addTr(scope.$index)"></i>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      </div>
      <!-- 搜索页面 -->
      <div v-if="current_nav==2">
        <div  class="prudence f14">为您搜索到 <span class="red">{{count}}条</span> 相关信息</div>
        <ul v-if="searchList.length>0">
          <li v-for="(item, index) in searchList" :key="index" :class="item.picked?'on':''">
            <div class="text flex">
              <div class="tit line1" @click="spreadFlow(item.id)">{{item.name}}</div>
            </div>
            <div class="img" v-show="spread_id==item.id">
              <div style="position: relative;">
                <img :src="site+item.path" />
                <div class="addbtn" v-if="chooseIds.indexOf(item.id.toString())==-1" style="position: absolute;top: 0;right: 20px;" @click="choice_fun(item.id)">使用</div>
                <div v-else style="position: absolute;top: 0;right: 20px;" >已使用</div>
                <!-- <div  style="position: absolute;top: 4px;left: 20px;" >{{item.name}}</div> -->
              </div>
            </div>
          </li>
        </ul>
        <el-empty v-else description="暂无清单！"></el-empty>
        <div class="flex-b-c mb-10" v-if="count>=1">
          <!-- 分页 -->
          <el-pagination v-model:current-page="current_page" @current-change="handleCurrentChange"
            :page-size="pageSize" layout="prev, pager, next, jumper" :total="count">
          </el-pagination>
        </div>
        <!--弹窗选择产品-->
        <el-dialog v-model="dialogFormVisible" title="选择关联的产品" width="500" :close-on-click-modal="false" :close-on-press-escape="false">
          <div class="input" style="height: 35px; margin-bottom: 5px; padding-left: 5px;">
            <span class="f14">选择产品：</span>
            <el-select v-model="product_value" placeholder="请选择产品" style="width: 200px;">
              <el-option value="">请选择</el-option>
              <el-option v-for="item in product_list" :key="item" :label="item" :value="item"></el-option>
            </el-select>
            <el-button size="small" @click="addProduct();" style="line-height: 30px; height:30px; margin-left: 15px;">添加</el-button>
          </div>
          <div class="input" style="line-height: 35px;height: 80px;">
            <el-tag v-for="tag in product_info" size="large" :key="tag" closable type="primary" style="margin: 5px; font-size: 14px;" @close="handleClose(tag)">
              {{ tag }}
            </el-tag>
          </div>
          <div style="text-align: right;margin-top: 10px;"><el-button size="small" @click="chooseFlow();" style="line-height: 30px; height:30px; margin-right: 15px;">确 定</el-button></div>
        </el-dialog>
      </div>
      <!-- 编辑系统流程图 -->
      <el-form ref="formScreening" label-width="80px" v-if="current_nav==4">
<!--        <div class="input">
          <span class="f14">产品名称：</span>{{name}}
        </div>-->
        <div class="input" style="height: 40px; margin-bottom: 5px; padding-left: 5px;">
          <span class="f14">选择产品：</span>
          <el-select v-model="product_value" placeholder="请选择产品" style="width: 200px;">
            <el-option value="">请选择</el-option>
            <el-option v-for="item in product_list" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-button size="small" @click="addProduct();" style="line-height: 30px; height:30px; margin-left: 15px;">添加</el-button>
        </div>
        <div class="input" style="line-height: 35px;height: 35px;">
          <el-input-tag v-model="product_info" tag-type="" tag-effect="plain" clearable placeholder="请添加关联产品" />
        </div>
        <el-table :data="flowInfo" ref="multipleTable" border show-header>
          <el-table-column label="步骤" width="54" align="center">
            <template #default="scope">{{(scope.$index)+1}}</template>
          </el-table-column>
          <el-table-column label="流程信息" align="center">
            <template #default="scope">
              <el-row>
                <el-col :span="5" style="text-align: center; font-weight: bold;">流程名称</el-col>
                <el-col :span="1"></el-col>
                <el-col :span="10" style="text-align: center; font-weight: bold;">流程说明</el-col>
<!--                <el-col :span="10" style="text-align: center; font-weight: bold;">技术要求</el-col>-->
              </el-row>
              <el-row>
                <el-col :span="5" style="padding: 0 3px;"><el-input v-model="scope.row.name" placeholder="流程名称" type="textarea"></el-input></el-col>
                <el-col :span="1"></el-col>
                <el-col :span="10" style="padding: 0 3px;"><el-input v-model="scope.row.content" :rows="2" type="textarea" placeholder="请输入流程说明"></el-input></el-col>
<!--                <el-col :span="10" style="padding: 0 3px;"><el-input v-model="scope.row.technical_requirement" :rows="2" type="textarea" placeholder="请输入技术要求"></el-input></el-col>-->
              </el-row>
              <el-row>
                <el-col style="padding: 5px 5px 5px 10px; text-align: left;">
                  <el-checkbox v-model="scope.row.is_out">外包</el-checkbox>
                  <el-checkbox v-model="scope.row.is_special">特殊</el-checkbox>
                  <el-checkbox v-model="scope.row.is_key">关键</el-checkbox>
                  <el-checkbox v-model="scope.row.is_affirm">需确认</el-checkbox>
                  <el-checkbox v-model="scope.row.is_need">焊接</el-checkbox>
                  <el-checkbox v-model="scope.row.is_line">生产流程</el-checkbox>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </template>
    <template v-slot:foot_msg>
      <span v-if="current_nav==0" style="display:flex;align-items: center;height: 4.1vw;color:red;">根据企业产品进行工艺流程图的编写，可以输入产品名称直接选择适合的流程图，也可以自行上传流程图片，如以上都不满足，可以根据产品自行建立符合的生产流程。</span>
      <!-- <button v-if="current_nav==0" @click="saveFlowList" style="float: right;">保存</button> -->
      <button v-if="current_nav==3||current_nav==1||current_nav==4" @click="back" style="float: right;width:auto;">返回</button>
      <button v-if="current_nav==1" @click="saveFlow" style="float: right;width:auto;margin-right:10px;">保存</button>
      <button v-if="current_nav==2" @click="back" style="float: right;">返回</button>
      <button v-if="current_nav==3" @click="saveFlowList" style="float: right;width:auto;margin-right:10px;">保存</button>
      <button v-if="current_nav==4" @click="saveSystemFlow" style="float: right;width:auto;margin-right:10px;">保存</button>
    </template>
  </RightSide>
</template>
<script>
import { ref,reactive,toRefs,getCurrentInstance,onMounted } from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'

export default {
  name: 'Structure',
  components: {
    RightSide
  },
  props: {},
  setup (props,context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    let project_id = Api.sessionGet('project_id')
    let dialogFormVisible = ref(false);
    let _this = reactive({
      action:Api.UPLOAD_URL,
      site:Api.WEB_SITE,
      current_nav:0,
      keyword:'',
      pageSize:10,
      count:0,
      current_page:1,
      flowList:[],//已添加流程图
      flowImgList:[],//上传的流程列表
      searchList:[],//搜索页面
      flowInfo:[
        {name:'',content:'',technical_requirement:'',is_out:false,is_key:false,is_special:false,is_affirm:false,is_need:false,is_line:false},
      ],
      name:'',
      flow_id:0,
      spread_id:0,
      showClassify:true,
      chooseIds:[],
      product_list:[],//产品列表
      product_value:'',
      product_info:[],//关联产品信息
      choice_id:0,
    })
    onMounted(()=>{
      getFlowList();
      getProductList();
    });
    //获取公共头部
    const getHeader = ()=>{
      let headers = proxy.$commonApi.getHeader();
      let header = {}
      header.USERKEY = headers.userKey
      header.USERTOKEN = headers.token
      return header
    }
    // 获取产品列表
    const getProductList = () => {
      proxy.$post('project/getProductList', {}).then(res => {
        if (res.code == 0) {
          _this.product_list = res.data || []
        } else {
          Api.alert(res.msg)
        }
      });
    }
    //获取工艺流程图
    const getFlowList = ()=>{
      proxy.$post('project/getFlowList',{
        project_id:project_id
      }).then(res=>{
        let list = res||[]
        let flowImgList = []
        list.forEach((v,k)=>{
          if(v.type==1)flowImgList.push(v)
          if(k==0)_this.spread_id = v.id
        })
        _this.flowImgList = flowImgList
        _this.chooseIds = Api.array_column(list,'flow_id')
        _this.flowList = res
        if (_this.flowList.length>0){
          _this.current_nav = 0;
        }else {
          _this.current_nav = 2;
          _this.current_page = 1;
          getSearchList();
        }
      });
    }
    // 编辑系统流程图
    const editSystemFlow = (item) => {
      _this.flow_id = item.id
      let config = item.flow_config
      config.forEach((v,k)=>{
        if(v.is_out==1){
          config[k].is_out = true
        }else{
          config[k].is_out = false
        }
        if(v.is_key==1){
          config[k].is_key = true
        }else{
          config[k].is_key = false
        }
        if(v.is_special==1){
          config[k].is_special = true
        }else{
          config[k].is_special = false
        }
        if(v.is_affirm==1){
          config[k].is_affirm = true
        }else{
          config[k].is_affirm = false
        }
        if(v.is_need==1){
          config[k].is_need = true
        }else{
          config[k].is_need = false
        }
        if(v.is_line==1){
          config[k].is_line = true
        }else{
          config[k].is_line = false
        }
      })
      _this.flowInfo = config
      _this.name = item.name
      _this.product_info = item.product_list
      _this.current_nav = 4
    }
    // 保存系统流程图
    const saveSystemFlow = () => {
      let flowInfo = _this.flowInfo
      flowInfo.forEach((v,k)=>{
        if(v.is_out=='true'||v.is_out===true){
          flowInfo[k].is_out = 1
        }else{
          flowInfo[k].is_out = 0
        }
        if(v.is_key=='true'||v.is_key===true){
          flowInfo[k].is_key = 1
        }else{
          flowInfo[k].is_key = 0
        }
        if(v.is_special=='true'||v.is_special===true){
          flowInfo[k].is_special = 1
        }else{
          flowInfo[k].is_special = 0
        }
        if(v.is_affirm=='true'||v.is_affirm===true){
          flowInfo[k].is_affirm = 1
        }else{
          flowInfo[k].is_affirm = 0
        }
        if(v.is_need=='true'||v.is_need===true){
          flowInfo[k].is_need = 1
        }else{
          flowInfo[k].is_need = 0
        }
        if(v.is_line=='true'||v.is_line===true){
          flowInfo[k].is_line = 1
        }else{
          flowInfo[k].is_line = 0

        }
      })
      let data = {}
      data.name = _this.name
      data.config = flowInfo
      data.flow_id = _this.flow_id
      data.product_info = _this.product_info;
      if(_this.product_info.length===0){
        Api.msg('请填写产品名称！')
        return false
      }
      let isEmpty = false
      let content_flag = false;
      //let technical_requirement_flag = false;
      flowInfo.forEach(v=>{
        if(v.name=='')isEmpty = true
        if(v.content=='')content_flag = true;
        //if(v.technical_requirement=='')technical_requirement_flag = true;
      })
      if(isEmpty){
        Api.msg('流程名称不能为空！')
        return false
      }
      if(content_flag){
        Api.msg('流程说明不能为空！')
        return false
      }
      /*if(technical_requirement_flag){
        Api.msg('技术要求不能为空！')
        return false
      }*/
      proxy.$post('project/saveSystemFlow',data).then(res=>{
        Api.msg(res.msg,0,'',()=>{
          if(res.code==0){
            getFlowList()
            _this.current_nav = 0;
            resetPercent();
          }
        })
      });
    }
    //编辑流程
    const editFlow = (item)=>{
      if(item.type==1){
        _this.current_nav = 3
      }else{
        _this.flow_id = item.id
        let config = item.flow_config
        config.forEach((v,k)=>{
          if(v.is_out==1){
            config[k].is_out = true
          }else{
            config[k].is_out = false
          }
          if(v.is_key==1){
            config[k].is_key = true
          }else{
            config[k].is_key = false
          }
          if(v.is_special==1){
            config[k].is_special = true
          }else{
            config[k].is_special = false
          }
          if(v.is_affirm==1){
            config[k].is_affirm = true
          }else{
            config[k].is_affirm = false
          }
          if(v.is_need==1){
            config[k].is_need = true
          }else{
            config[k].is_need = false
          }
          if(v.is_line==1){
            config[k].is_line = true
          }else{
            config[k].is_line = false
          }
        })
        _this.flowInfo = config
        _this.name = item.name
        _this.product_info = item.product_list
        _this.current_nav = 1
      }
    }
    //删除流程
    const delFlow = (id)=>{
      Api.confirm('您确定要删除流程图吗？',()=>{
        proxy.$post('project/delFlow',{id:id}).then(res=>{
        Api.msg(res.msg,0,'',()=>{
          if(res.code==0){
            getFlowList();
            resetPercent();
          }
        })
      });
      })
    }
    //搜索
    const getSearchList = ()=>{
      var t = Api.loading()
      let param = {}
      param.keyword = _this.keyword
      param.page = _this.current_page
      param.pageSize = _this.pageSize
      proxy.$post('flow/list',param).then(res=>{
        _this.searchList = []
        let list = res.data
        list.forEach((v,k)=>{
          if(k==0)_this.spread_id = v.id
        })
        _this.searchList = list
        _this.pageSize = res.pageSize
        _this.count = res.count
        _this.current_page = res.current_page
        Api.close(t)
      })
    }
    const search = ()=>{
      _this.current_nav = 2
      _this.current_page = 1
      getSearchList()
    }
    // 分页事件
    const handleCurrentChange = (page)=>{
      _this.current_page = page
      getSearchList()
    }
    //收起/展开流程图
    const spreadFlow = (id)=>{
      if(_this.spread_id==id){
        _this.spread_id = 0
      }else{
        _this.spread_id = id
      }
    }
    //选择流程图
    const chooseFlow = ()=>{
      proxy.$post('product/chooseFlow',{id:_this.choice_id,product_info:_this.product_info}).then(res=>{
        Api.msg(res.msg)
        if(res.code==0){
          getFlowList();
          resetPercent();
        }
      });
    }
     //保存上传工艺流程图
     const saveFlowList = ()=>{
      let flowImgList = _this.flowImgList
      proxy.$post('project/saveFlowList',{flowList:flowImgList}).then(res=>{
        Api.msg(res.msg,0,'',()=>{
          if(res.code==0){
            getFlowList()
            _this.current_nav = 0
            resetPercent();
          }
        })
      })
    }
    //返回首页
    const back = ()=>{
      _this.current_nav = 0
      getFlowList()
    }
    // 添加流程
    // 上移
    const moveUp = (index)=>{
      if(index===0){
        return
      }
      let list = _this.flowInfo
      list.splice(index - 1, 1, ...list.splice(index, 1, list[index - 1]));
      _this.flowInfo = list
    }
    // 下移
    const moveDown = (index)=>{
      let list = _this.flowInfo
      if(index===list.length){
        return
      }
      list.splice(index, 1, ...list.splice(index + 1, 1, list[index]));
      _this.flowInfo = list
    }
    //删除
    const deleteTr = (index)=>{
      if (index != 0) {
        _this.flowInfo.splice(index, 1);
      }
    }
    //添加
    const addTr = (index)=>{
      var newTr = {name:'',content:'',technical_requirement:''}
      _this.flowInfo.splice(index+1, 0, newTr)
    }
    //保存流程图
    const saveFlow = ()=>{
      let flowInfo = _this.flowInfo
      flowInfo.forEach((v,k)=>{
        if(v.is_out=='true'||v.is_out===true){
          flowInfo[k].is_out = 1
        }else{
          flowInfo[k].is_out = 0
        }
        if(v.is_key=='true'||v.is_key===true){
          flowInfo[k].is_key = 1
        }else{
          flowInfo[k].is_key = 0
        }
        if(v.is_special=='true'||v.is_special===true){
          flowInfo[k].is_special = 1
        }else{
          flowInfo[k].is_special = 0
        }
        if(v.is_affirm=='true'||v.is_affirm===true){
          flowInfo[k].is_affirm = 1
        }else{
          flowInfo[k].is_affirm = 0
        }
        if(v.is_need=='true'||v.is_need===true){
          flowInfo[k].is_need = 1
        }else{
          flowInfo[k].is_need = 0
        }
        if(v.is_line=='true'||v.is_line===true){
          flowInfo[k].is_line = 1
        }else{
          flowInfo[k].is_line = 0
        }
      })
      let data = {}
      data.name = _this.name
      data.config = flowInfo
      data.flow_id = _this.flow_id
      data.product_info = _this.product_info;
      if(_this.product_info.length===0){
        Api.msg('请填写产品名称！')
        return false
      }
      let isEmpty = false
      let content_flag = false;
      //let technical_requirement_flag = false;
      flowInfo.forEach(v=>{
        if(v.name=='')isEmpty = true
        if(v.content=='')content_flag = true;
        //if(v.technical_requirement=='')technical_requirement_flag = true;
      })
      if(isEmpty){
        Api.msg('流程名称不能为空！')
        return false
      }
      if(content_flag){
        Api.msg('流程说明不能为空！')
        return false
      }
      /*if(technical_requirement_flag){
        Api.msg('技术要求不能为空！')
        return false
      }*/
      proxy.$post('project/saveFlow',data).then(res=>{
        Api.msg(res.msg,0,'',()=>{
          if(res.code==0){
            getFlowList();
            _this.current_nav = 0;
            resetPercent();
          }
        })
      });
    }
    //上传成功
    const handleAvatarSuccess = (res)=>{
      if(res.code==0||res.code==200){
        let item = {id:0,name:'',company_id:0,path:'',project_ids:[]}
        item.path = res.data.src
        _this.flowImgList.push(item)
      }else{
        Api.msg(res.msg)
      }
    }
    //切换导航
    const changeNave = (nav)=>{
      if(nav==1&&_this.current_nav!=1){
        _this.flowInfo =  [
          {name:'',content:'',technical_requirement:'',is_out:0,is_key:0,is_special:0,is_affirm:0,is_need:0,is_line:0},
        ]
      }
      if (nav==1){
        _this.product_info = [];
        _this.product_value = '';
        _this.flow_id = 0;
        _this.name = '自建流程';
      }
      _this.current_nav = nav
    }
    // 添加选择的产品
    const addProduct = ()=>{
      if (_this.product_value == '') {
        Api.msg('请选择产品！', 2);
        return false;
      }
      if (_this.product_info.includes(_this.product_value)) {
        Api.msg('已添加过该产品！', 2);
        return false;
      }
      _this.product_info.push(_this.product_value);
    }
    // 删除
    const handleClose = (tag) => {
      _this.product_info.value.splice(_this.product_info.value.indexOf(tag), 1);
    }
    // 选择使用
    const choice_fun = (id)=>{
      _this.choice_id = id;
      dialogFormVisible.value = true;
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -4;
      context.emit("updatePercent", data)
    }
    return {
      ...toRefs(_this),
      dialogFormVisible,handleClose,choice_fun,
      getHeader,
      getFlowList,
      getProductList,
      editFlow,
      delFlow,
      getSearchList,
      handleCurrentChange,
      search,
      spreadFlow,
      chooseFlow,
      saveFlowList,
      back,
      moveUp, moveDown, deleteTr, addTr,
      saveFlow,
      handleAvatarSuccess, changeNave,
      resetPercent,
      editSystemFlow, saveSystemFlow,
      addProduct,
    };
  }
}
</script>

<style scoped>
/* 中间部分 */
  form{
    margin-top:24px;
    margin-bottom:24px;
  }
  form>div{ margin-bottom:24px; }
  .input{
    background:#F5F6FA;
    border-radius:4px;
    height:36px;
    line-height:36px;
  }
  input{
    background: #f5f6fa;
    width: 14vw;
    height:36px;
    line-height:36px;
    border-radius:4px;
    border:1px solid #DADCE5;
    padding-left:15px;
    vertical-align:top;
  }
  .input input{
    background:transparent;
  }
  .input i{
    width:50px;
    height:36px;
    font-size:22px;
    background: var(--color-text-blue);
    color:#fff;
    text-align: center;
    display: inline-block;
    border-radius:0 4px 4px 0;
    cursor:pointer;
  }
  button{
    background:#3061F2;
    color:#fff;
    font-size:14px;
    border-radius:4px;
    padding:0 14px;
    height:36px;
    line-height: 36px;
    border:none;
    cursor: pointer;
  }
  button i{
    margin-right:6px;
  }
  .btns button+button{
    margin-left:15px;
  }
  :deep(.el-table th.el-table__cell){
    background:#eaeffe;
    color:#161823;
    border-color: #DADCE5;
    border-bottom:transparent;
  }
  :deep(.el-table.is-scrolling-none th.el-table-fixed-column--right){
    background:#eaeffe;
  }
  :deep(.el-input__wrapper){
    border:none;
    box-shadow:none;
  }

  :deep(.el-input__wrapper):hover,
  :deep(.el-input__wrapper.is-focus){
    box-shadow:none;
  }
  :deep(.el-table) i+i{
    margin-left:10px;
  }
  :deep(.el-table) i.red{
    border:1px solid #FE2C55;
    border-radius:50%;
    padding:3px;
    font-size:12px;
  }
  :deep(.el-table) i.blue{
    border:1px solid #3061F2;
    border-radius:50%;
    padding:3px;
    font-size:12px;
  }
  .generate{
    border:1px solid #CFD2E6;
    width:100%;
    height:480px;
    overflow-y:auto;
    border-radius:4px;
    background:#fff;
  }
  .prudence{
    background: #E1EDFA;
    border: 1px solid #CFD2E6;
    border-radius: 4px;
    height:36px;
    line-height:36px;
    padding:0 15px;
    margin:15px 0;
  }
  ul{
    margin:15px 0;
  }
  li{ margin:2px 0;cursor: pointer; }
  li .text{
    background: #F5F6FA;
    height: 40px;
    line-height:40px;
    width:100%;
    border-radius:4px;
    padding:0 6px 0 18px;
    box-sizing:border-box;
  }
  .tit{
    width:calc(100% - 150px);
    font-size:16px;
    font-family: 'PingFang-SC-Medium';
  }
  .addbtn{
    background: var(--color-text-blue);
    color: #fff;
    width: 48px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius:4px;
    margin-top:6px;
    margin-left:5px;
    font-size:14px;
    cursor:pointer;
  }
  li .img{
    /* display:none; */
    width:100%;
    background: #fff;
    border-radius:4px;
  }
  .img img{ width:100%; }
  li.on .line1{
    color:#FE2C55;
  }
  li.on .img{
    display:block;
  }
  .picked .addbtn{
    background: rgba(48, 97, 242, .2);
  }
/* 下 */
  .foot_msg button{
    height:40px;
    line-height:40px;
    font-size:16px;
    font-family: 'PingFang-SC-Bold';
    color: var(--color-text-white);
    background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
    border-radius:4px;
    border:none;
    width:200px;
    margin: calc((4.1vw - 40px) / 2) 0;
  }
</style>
