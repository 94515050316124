<!-- 特殊过程 -->
<template>
  <RightSide>
    <template v-slot:tit>特殊工艺</template>
    <template v-slot:write_msg>
      <div class="flex">
        <div v-for="(item, index) in special_process_data" :key="index" class="text" style="width: calc(100% - 4em);padding-top: 10px;">
          <div class="tit">
            {{item.product_name}}
          </div>
          <el-table :data="item.process_arr" border show-header>
            <el-table-column prop="process_name" label="工艺名称" width="150" align="center" />
            <el-table-column prop="equipment" label="使用设备" width="200" align="center">
              <template #default="scope">
                <el-input v-model="scope.row.equipment" type="textarea" rows="2" placeholder="设备名称"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作人" width="120" align="center">
              <template #default="scope">
                <el-select v-model="scope.row.staff_id" placeholder="请选择">
                  <el-option
                      label="请选择"
                      :value="0"
                  ></el-option>
                  <el-option
                      v-for="item in staff_list"
                      :key="item.staff_id"
                      :label="item.staff_name"
                      :value="item.staff_id"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="f14 red" style="text-align: center; margin: 10px;">
        <el-button type="primary" @click="handleSave">保 存</el-button>
      </div>
    </template>
  </RightSide>
</template>
<script>
import {reactive, toRefs, ref, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'

export default {
  name: 'SpecialProcess',
  components: {
    RightSide,
  },
  props: {},
  setup(props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    //公共数据
    const dialogFormVisible = ref(false);
    const production_form = ref({});
    let _this = reactive({
      site: Api.WEB_SITE,
      special_process_data: [],
      staff_list: [],
    });
    onMounted(() => {
      getSpecialFlow();
    })
    //获取生产任务信息
    const getSpecialFlow = () => {
      proxy.$post('company/getSpecialFlow').then(res => {
        if (res.code === 0) {
          _this.special_process_data = res.data;
          _this.staff_list = res.staff_list;
        }else {
          Api.msg(res.msg, 5);
        }
      });
    }
    //保存
    const handleSave = () => {
      console.log(_this.special_process_data);
      /*if(production_form.value.inspection_num.length==0) {
        Api.msg('请输入检验数量', 5);
        return;
      }
      proxy.$post('company/productionLineSave', production_form.value).then((res)=>{
        if(res.code==0||res.code==200){
          getSpecialFlow();
          dialogFormVisible.value = false;
        }
        Api.msg(res.msg, 2);
      });*/
    }
    // 禁用日期函数
    const disabledDate = (time) => {
      const startDate = new Date(production_form.value.product_date_int*1000);
      const endDate = new Date(production_form.value.delivery_date_int*1000);
      return startDate >= time.getTime() || time.getTime() >= endDate;
    };
    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -15;
      context.emit("updatePercent", data)
    }
    return {
      ...toRefs(_this),dialogFormVisible,production_form,
      handleSave,disabledDate,
      resetPercent,getSpecialFlow
    }
  }
}
</script>

<style scoped>
/* 中间部分 */
form {
  margin-top: 24px;
  margin-bottom: 24px;
}
form > .flex + div {
  margin-top: 16px;
}
button{
  background:#3061F2;
  color:#fff;
  font-size:14px;
  border-radius:4px;
  padding:0 14px;
  height:30px;
  line-height: 30px;
  border:none;
  cursor: pointer;
  /*margin-left: 10px;*/
}
button i{
  margin-right:6px;
}
.f14{
  font-size:14px;
}
.tit {
  line-height: 30px;
  height: 30px;
  width: 65%;
  font-size: 16px;
  font-family: 'PingFang-SC-Medium';
  margin-bottom: 5px;
}
.num {
  width: calc(35% - 48px);
  font-size: 14px;
  font-family: 'PingFang-SC-Medium';
}

.addbtn {
  background: var(--color-text-blue);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 4px;
  margin-top: 6px;
  margin-left: 5px;
}
.addbtn:hover{
  color: #0a84ff !important;
  border-color: var(--color-text-blue);
  border: 1px solid var(--color-text-blue) !important;
}
.addbtn:focus{
  background: var(--color-text-blue) !important;
  color: #fff !important;
  border: 1px solid var(--color-text-blue) !important;
}
/* 表格 */
.text{
  width: calc(90% - 4em - 10px);
  font-size: 14px;
}
:deep(.el-table .cell){
  text-overflow: clip !important;
}
:deep(.el-table th.el-table__cell){
  background:#eaeffe;
  color:#161823;
  border-color: #DADCE5;
  border-bottom:transparent;
}
:deep(.el-table.is-scrolling-none th.el-table-fixed-column--right){
  background:#eaeffe;
}
:deep(.el-table) input{
  border:none;
  height:25px;
  font-size:14px;
  color:#3D3D40;
}
:deep(.el-table) i+i{
  margin-left:10px;
}
:deep(.el-table) i.red{
  border:1px solid #FE2C55;
  border-radius:50%;
  padding:3px;
  font-size:12px;
}
:deep(.el-table) i.blue{
  border:1px solid #3061F2;
  border-radius:50%;
  padding:3px;
  font-size:12px;
}

:deep(.el-dialog__headerbtn .el-dialog__close){
  color: #ff0000 !important;
  font-size: 20px !important;
}
</style>
