<!-- 生产流程 -->
<template>
  <RightSide>
    <template v-slot:tit>生产流程</template>
    <template v-slot:write_msg>
      <div class="flex">
        <div class="text" style="width: calc(100% - 4em);padding-top: 10px;">
          <el-table :data="productive_task_data" border show-header>
            <el-table-column prop="product_name" label="产品名称" width="150" align="center" />
            <el-table-column prop="specifications" label="规格型号" width="100" align="center" />
            <el-table-column prop="product_num" label="生产数量" width="100" align="center" />
            <el-table-column prop="product_date" label="生产日期" width="120" align="center" />
            <el-table-column prop="delivery_date" label="交货日期" width="120" align="center" />
            <el-table-column fixed="right" label="操作" width="150" align="center">
              <template #default="scope">
                <el-button link type="primary" @click="handleProduction(scope.row)">填写生产流程</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="f14 red"></div>
        </div>
      </div>
      <!--弹窗-->
      <el-dialog v-model="dialogFormVisible" title="生产流程管理" width="800" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form ref="formRef" :model="production_form" label-width="100px" style="padding-right:30px;">
          <el-form-item label="产品名称：" style="font-weight: bold;">{{production_form.product_name}}</el-form-item>
          <el-form-item label="规格型号：">
            {{production_form.specifications}}
          </el-form-item>
          <el-form-item label="生产数量：">
            {{production_form.product_num}}
          </el-form-item>
          <el-form-item label="检验数量：">
            <el-input v-model="production_form.inspection_num" placeholder="检验数量" autocomplete="off" style="width: 150px;"/>
            <div class="red">&nbsp;*</div>
          </el-form-item>
          <el-form-item label="" label-width="20px">
            <el-table :data="production_form.process_card" border show-header style="margin-top: 5px;">
              <el-table-column label="步骤" width="100" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.step_name" placeholder="步骤"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="content" label="技术要求" width="200" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.technical_requirement" type="textarea" rows="2" placeholder="请输入技术要求"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="检验记录" width="120" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.inspection_record" type="textarea" rows="2" placeholder="检验记录"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="检验结果" width="120" align="center">
                <template #default="scope">
                  <el-select v-model="scope.row.inspection_result" placeholder="请选择">
                    <el-option label="合格" value="合格"></el-option>
                    <el-option label="不合格" value="不合格"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="日 期" width="120" align="center">
                <template #default="scope">
                <el-date-picker style="width: 100px;"
                    v-model="scope.row.inspection_date"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disabledDate"
                    placeholder="选择日期">
                </el-date-picker>
                </template>
              </el-table-column>
              <el-table-column label="操作人" width="120" align="center">
                <template #default="scope">
                  <el-select v-model="scope.row.staff_id" placeholder="请选择">
                    <el-option
                        label="请选择"
                        :value="0"
                    ></el-option>
                    <el-option
                        v-for="item in production_form.staff_list"
                        :key="item.staff_id"
                        :label="item.staff_name"
                        :value="item.staff_id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSave">保存</el-button>
        </span>
        </template>
      </el-dialog>
    </template>
  </RightSide>
</template>
<script>
import {reactive, toRefs, ref, onMounted, getCurrentInstance} from 'vue'
import RightSide from '@/components/content/home/RightSide.vue'

export default {
  name: 'ProductionLine',
  components: {
    RightSide,
  },
  props: {},
  setup(props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    //公共数据
    const dialogFormVisible = ref(false);
    const production_form = ref({});
    let _this = reactive({
      site: Api.WEB_SITE,
      productive_task_data: [],
    });
    onMounted(() => {
      getProductiveTask();
    })
    //获取生产任务信息
    const getProductiveTask = () => {
      proxy.$post('company/getProductiveTaskList').then(res => {
        if (res.code == 0) {
          _this.productive_task_data = res.data;
        }else {
          Api.msg(res.msg);
        }
      });
    }

    //
    const handleProduction = (row)=>{
      proxy.$post('company/getProductionLineInfo', {task_id:row.task_id,product_name:row.product_name}).then((res)=>{
        if(res.code==0){
          production_form.value = res.data;
          dialogFormVisible.value = true;
        }else{
          Api.msg(res.msg, 2);
        }
      });
    }

    //保存
    const handleSave = () => {
      if(production_form.value.inspection_num.length==0) {
        Api.msg('请输入检验数量', 5);
        return;
      }
      proxy.$post('company/productionLineSave', production_form.value).then((res)=>{
        if(res.code==0||res.code==200){
          getProductiveTask();
          dialogFormVisible.value = false;
        }
        Api.msg(res.msg, 2);
      });
    }
    // 禁用日期函数
    const disabledDate = (time) => {
      const startDate = new Date(production_form.value.product_date_int*1000);
      const endDate = new Date(production_form.value.delivery_date_int*1000);
      return startDate >= time.getTime() || time.getTime() >= endDate;
    };
    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -14;
      context.emit("updatePercent", data)
    }
    return {
      ...toRefs(_this),dialogFormVisible,production_form,
      handleSave,handleProduction,disabledDate,
      resetPercent,getProductiveTask
    }
  }
}
</script>

<style scoped>
/* 中间部分 */
form {
  margin-top: 24px;
  margin-bottom: 24px;
}

form > .flex + div {
  margin-top: 16px;
}
.prudence{
  background: #E1EDFA;
  border: 1px solid #CFD2E6;
  border-radius: 4px;
  height:36px;
  line-height:36px;
  padding:0 15px;
  margin:15px 0;
}
button{
  background:#3061F2;
  color:#fff;
  font-size:14px;
  border-radius:4px;
  padding:0 14px;
  height:30px;
  line-height: 30px;
  border:none;
  cursor: pointer;
  /*margin-left: 10px;*/
}
button i{
  margin-right:6px;
}
.f14{
  font-size:14px;
}
ul {
  height: 420px;
  overflow: hidden;
  margin: 15px 0;
}

li {
  background: #F5F6FA;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-radius: 4px;
  margin: 2px 0;
  padding: 0 6px 0 18px;
  box-sizing: border-box;
}

.tit {
  width: 65%;
  font-size: 16px;
  font-family: 'PingFang-SC-Medium';
}

.num {
  width: calc(35% - 48px);
  font-size: 14px;
  font-family: 'PingFang-SC-Medium';
}

.addbtn {
  background: var(--color-text-blue);
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 4px;
  margin-top: 6px;
  margin-left: 5px;
}
.addbtn:hover{
  color: #0a84ff !important;
  border-color: var(--color-text-blue);
  border: 1px solid var(--color-text-blue) !important;
}
.addbtn:focus{
  background: var(--color-text-blue) !important;
  color: #fff !important;
  border: 1px solid var(--color-text-blue) !important;
}

.picked .addbtn {
  background: rgba(48, 97, 242, .2);
}

/* 下 */
.foot_msg button {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: 'PingFang-SC-Bold';
  color: var(--color-text-white);
  background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
  border-radius: 4px;
  border: none;
  width: 200px;
  margin: calc((4.1vw - 40px) / 2) 0;
}

/* 弹出框 */
ol {
  margin: 15px 0;
}

.cont li.picked .addbtn {
  background: var(--color-text-blue);
}

.blue.hand {
  border: 0;
  background: none;
}

.flex > span {
  font-size: 14px;
  color: #6D6E73;
  margin-right: 10px;
  line-height: 32px;
}
/* 表格 */
.text{
  width: calc(90% - 4em - 10px);
  font-size: 14px;
}
:deep(.el-table .cell){
  text-overflow: clip !important;
}
:deep(.el-table th.el-table__cell){
  background:#eaeffe;
  color:#161823;
  border-color: #DADCE5;
  border-bottom:transparent;
}
:deep(.el-table.is-scrolling-none th.el-table-fixed-column--right){
  background:#eaeffe;
}
:deep(.el-table) input{
  border:none;
  height:25px;
  font-size:14px;
  color:#3D3D40;
}
:deep(.el-table) i+i{
  margin-left:10px;
}
:deep(.el-table) i.red{
  border:1px solid #FE2C55;
  border-radius:50%;
  padding:3px;
  font-size:12px;
}
:deep(.el-table) i.blue{
  border:1px solid #3061F2;
  border-radius:50%;
  padding:3px;
  font-size:12px;
}

:deep(.el-dialog__headerbtn .el-dialog__close){
  color: #ff0000 !important;
  font-size: 20px !important;
}
</style>
